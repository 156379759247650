import React from 'react';
import { Importer, ImporterField } from 'react-csv-importer';

import 'react-csv-importer/dist/index.css';
import { idiomaCSV } from '../../constantes/global';

export const SubirPersonasCSV = ({ importarDatos }) => {
    return (
        <Importer
            locale={idiomaCSV}
            dataHandler={(rows, { startIndex }) => importarDatos(rows)}
            defaultNoHeader={false} // optional, keeps "data has headers" checkbox off by default
            restartable={true} // optional, lets user choose to upload another file when import is complete
        >
            <ImporterField name="numeroDocumento" label="numeroDocumento" />
            <ImporterField name="primerApellido" label="primerApellido" />
            <ImporterField name="segundoApellido" label="segundoApellido" />
            <ImporterField name="nombre" label="nombre" />
            <ImporterField name="segundoNombre" label="segundoNombre" />
            <ImporterField name="sexo" label="sexo" />
            <ImporterField name="fechaNacimiento" label="fechaNacimiento" />
            <ImporterField name="tipoDocumento" label="tipoDocumento" />
        </Importer>
    );
}
