import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import { TraeNombre } from '../hooks/Funciones'

const VerServicios = ({ data, seleccionaRegistro, baseUrl }) => {

	return (
		<MDBTable hover>
			<HeaderTabla array={['Img', 'Nombre', 'Tipo', 'Tarifa', 'Tipo Ingreso', '']} />
			<MDBTableBody>

				{data.map((item, index) => {
					return (
						<tr key={index} className="tabla_productos">
							<td><div className="ImgProducto"><img src={`${baseUrl}${item.imagen}`} /></div></td>
							<td>{item.nombre}</td>
							<td>{item.tipo}</td>
							<td>{item.tarifa}</td>
							<td>{item.tipoingreso}</td>
							<td className="tabla_funciones">
								<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'} />
							</td>
						</tr>
					)
				})}

			</MDBTableBody>
		</MDBTable>
	)
}

export default VerServicios