import { faCalendarDays, faCreditCardAlt, faTicket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { QRCodeSVG } from 'qrcode.react'
import React from 'react'
import { ApiPaths } from '../../utils'
import { NumericFormat } from 'react-number-format'
import { Image } from 'react-bootstrap'

const arreglarFechaPedido = (fecha) => moment(fecha).format("YYYY-MM-DD h:mm:ss")

const TicketPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className="impresionPdf pagebreak ticketPrint" >
        <div className='row headReserva'>
            <div className='col-md-3 col-sm-3'>
                <FontAwesomeIcon icon={faTicket} size="3x" style={{ marginTop: 20 }} />
            </div>
            <div className='col-md-9 col-sm-9'>
                <span>Estado: <b className={`estados ${props.estado}`}>{props.estado}</b></span>
                <h2><b>{props.tipoIngreso?.nombre}</b ></h2>
                {props.codigoReserva && <> <span>Reserva: <b>{props.codigoReserva}  <br></br></b> TIPO R: <b>OPERADORA</b></span><br></br></>}
                <span>Fecha de realización de la Reserva: <b>{arreglarFechaPedido(props.fechaReserva)}</b></span>
                <br></br>
                <div className='FechaRerserva'>
                    <b>Fecha de Reservas: </b>
                    <span>
                        {props.fechaReservaInicial}
                    </span>
                    {props.fechaReservaFinal && <div className='FechaRerserva'>
                        <span>
                            <FontAwesomeIcon icon={faCalendarDays} size="1x" style={{ marginRight: 10 }} />
                            {props.fechaReservaFinal}
                        </span>
                    </div>}
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12 columaReserva'>
                <div className='codeQR'>
                    <QRCodeSVG value={props.codigoReserva} size='' />
                </div>
                <h5><FontAwesomeIcon icon={faCreditCardAlt} size="1x" style={{ marginRight: 0 }} /> Datos de pago:</h5>
                <hr></hr>
                <span><b>Total Reserva:</b></span>
                <h3><b><NumericFormat displayType="text" value={props.total} prefix={'$'} thousandSeparator={true} /></b></h3>
                <p><b>Total Entradas: </b><NumericFormat displayType="text" value={props.totalEntradas} prefix={'$'} thousandSeparator={true} /> </p>
                <p><b>Total Seguros: </b><NumericFormat displayType="text" value={props.totalSeguros} prefix={'$'} thousandSeparator={true} /> </p>
                <p><b>Servicios Uton: </b><NumericFormat displayType="text" value={props.totalServicioGuia} prefix={'$'} thousandSeparator={true} /> </p>
                <p><b>Servicios Transporte: </b><NumericFormat displayType="text" value={props.totalServicioVehiculo} prefix={'$'} thousandSeparator={true} /> </p>
                <hr></hr>
                <div className='informacion-reserva-imagen'>
                    <Image
                        src={ApiPaths.operadores + props.operadorImg}
                    />
                    <div>
                        <p><b>Operador:</b></p>
                        <p>{props.operador}</p>
                    </div>
                </div>


            </div>
        </div>
    </div >

))

export default TicketPrint