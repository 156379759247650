import React, { useState, useEffect, useContext } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import axios from 'axios'
import moment from 'moment'
import { errores } from '../hooks/Funciones'
import { Alert, Form } from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import { ApiPaths } from '../../utils';
import { NumericFormat } from 'react-number-format';


const SubirAbonos = ({ baseUrl, setFuncion, select }) => {
	const [registro, setRegistro] = useState({ imagePrev: null })
	const [error, setError] = useState({})
	const { user } = useContext(UserContext)
	const [mediosPago, setmediosPago] = useState([])

	useEffect(() => {
		const peticionTraeDatos = async () => {
			try {
				const response = await axios.get(ApiPaths.metodosPago)
				setmediosPago(response?.data.map(item => ({ ...item, label: item.nombre, value: item.nombre })))

			} catch (error) {
				console.log(error)
			}

		}
		peticionTraeDatos()
	}, [])

	const peticionPost = (e) => {
		e.preventDefault()
		const fecha = moment(new Date()).format("YYYY-MM-DD-HH-mm-ss");

		let { valor, mediosdepago } = registro

		let f = new FormData()

		f.append('valor', valor)
		f.append('medio_pago', mediosdepago)
		f.append('fecha', fecha)
		f.append('id_operador', select.id)
		f.append('id_usuario', user.id)

		f.append('METHOD', 'POST')
		axios.post(baseUrl, f)
			.then(response => {
				console.log('enviado')
				setFuncion('abonos')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})

	}

	const recibeImagen = e => {
		let picture = e.target.files[0]
		setRegistro({ ...registro, imagePrev: URL.createObjectURL(picture), imagen: picture })
	}


	return (
		<div>
			<Form onSubmit={peticionPost} >
				<MDBContainer>
					<MDBRow>
						<MDBCol>
							<hr />
							{error.activo ?
								<span className="error"> Error: {error.mensaje}</span> : null
							}

							<div className="row sinMargen">
								<div className="col-md-6">
									<NumericFormat
										required
										prefix="$"
										thousandSeparator
										className='form-control'
										placeholder='Valor'
										value={registro.valor}
										onValueChange={(values) => setRegistro({ ...registro, valor: values.value })} />
								</div>

								<div className="col-md-6">
									<select
										required
										className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll"
										value={registro.mediosdepago}
										onChange={e => setRegistro({ ...registro, mediosdepago: e.target.value })}>
										<option value="">Selecciona Medio de Pago</option>
										{mediosPago.map(item => (
											<option key={item.id} value={item.value}>{item.value}</option>
										))}
									</select>

								</div>

							</div>

							<div className="text-center mt-4">
								<div className="col text-center">
									<button
										type='submit'
										className="btn boton_estandar"
									>Subir Abonos
									</button>
								</div>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</Form>
			<div className="text-center mt-4">
								<div className="col text-center">
									<button
										className="btn boton_estandar"
										onClick={() => setFuncion('abonos')}
									>Cancelar
									</button>
								</div>
							</div>
		</div>
	)
}

export default SubirAbonos