import React, { useContext } from 'react'
import SubirReservas from '../SubirReservas'
import { ReservaProvider } from '../../../context/ReservaContext'
import { UserProvider } from '../../../context/UserContext'
import Logo from '../../../paginas/img/logo.png'
const CrearReserva = () => (
    <ReservaProvider>
        <UserProvider>
            <div className='container mt-3 mb-3'>
                <div className='imagen-reserva-img'>
                    <img src={Logo} />
                </div>
                <SubirReservas />
            </div>
        </UserProvider>
    </ReservaProvider>)


export default CrearReserva