
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Logo from '../../paginas/img/logo.png'

const Estados = (dato) => {
    if (dato === 'PENDIENTE') {
        return 'P'
    } else if (dato === 'APROBADO') {
        return 'A'
    } else if (dato === 'RECHAZADO') {
        return 'R'
    } else if (dato === 'CANCELADO') {
        return 'C'
    } else if (dato === 'COMPLETADO') {
        return 'C'

    }
}

const ComponentToPrint = React.forwardRef((props, ref) => (
    <div ref={ref} className="impresionPdf pagebreak" >
        <h2><img src={Logo} className="logoPdf" /> Informe de Reservas:</h2>
        <MDBTable small responsive>
            <MDBTableBody>

                <tr>
                    <td><b>Total Reservas:</b></td>
                    <td><NumericFormat displayType="text" value={props.reservasImprimirTotales?.totalReservas} prefix={'$'} thousandSeparator={true} /></td>
                    <td><b>Servicio de Transporte:</b></td>
                    <td><NumericFormat displayType="text" value={props.reservasImprimirTotales?.totalServicioVehiculo} prefix={'$'} thousandSeparator={true} /></td>
                    <td><b>Servicio de Alimentacion:</b></td>
                    <td><NumericFormat displayType="text" value={props.reservasImprimirTotales?.totalServicioAlimentacion} prefix={'$'} thousandSeparator={true} /></td>
                    <td><b>Servicio Guianza:</b></td>
                    <td><NumericFormat displayType="text" value={props.reservasImprimirTotales?.totalServicioGuia} prefix={'$'} thousandSeparator={true} /></td>
                </tr>
                <tr>
                    <td><b>Servicio de Hospedaje:</b></td>
                    <td><NumericFormat displayType="text" value={props.reservasImprimirTotales?.totalServicioHospedaje} prefix={'$'} thousandSeparator={true} /></td>
                    <td><b>Servicio de Entradas:</b></td>
                    <td><NumericFormat displayType="text" value={props.reservasImprimirTotales?.totalServicioEntradas} prefix={'$'} thousandSeparator={true} /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </MDBTableBody>
        </MDBTable>

        <MDBTable small responsive>
            <tbody>
                <tr className="tabla">
                    <th>Estado</th>
                    <th>Cod</th>
                    <th>Creación</th>
                    <th>Ingreso</th>
                    <th>Reserva</th>
                    <th>Total</th>
                    <th>Operador</th>

                </tr>
            </tbody>
            <MDBTableBody>

                {props.data && props.data.map((item, index) => {

                    return (
                        <tr key={index} className="tabla">
                            <td><span className={`estados ${item.estado}`}>{Estados(item.estado)}</span></td>
                            <td>{item.codigoReserva}</td>
                            <td>{item.fechaReserva}</td>
                            <td>{item.tipoingreso}</td>
                            <td>{item.fechaReservaInicial} {item.fechaReservaFinal && `- ${item.fechaReservaFinal}`} </td>
                            <td> <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} /></td>
                            <td>{item.operador}</td>
                        </tr>
                    )
                })

                }

            </MDBTableBody>
        </MDBTable>
    </div >

))


export default ComponentToPrint;