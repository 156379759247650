import { NumericFormat } from 'react-number-format';
import Tabla from '../componentes/Tabla';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp, faDollar, faDollarSign, faEye, faFilePen, faPencil, faTrashAlt, faX } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import UserContext from '../../context/UserContext';
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import { ApiPaths, KeysNuvei } from '../../utils';
import useTraeDatos from "../hooks/useTraeDatos";
import * as RUTAS from '../../constantes/rutas';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { AlertaConfirmacion } from '../componentes/AlertaConfirmacion';
import { toast } from 'react-toastify';

const baseUrlGeneral = window.location.origin;
const API_LOGIN_DEV = KeysNuvei.API_LOGIN_DEV;
const API_KEY_DEV = KeysNuvei.API_KEY_DEV;
const API_URL_DEV = KeysNuvei.API_URL_DEV;

const VerReservas = ({ reservas, seleccionaRegistro, exportarCSV }) => {
	// Obtener el contexto del usuario
	const { user } = useContext(UserContext);
	// Definir estados locales
	const [mostrarAlerta, setMostrarAlerta] = useState(false);
	const [alertaTexto, setAlertaTexto] = useState('')
	const [saldosPagar, setSaldosPagar] = useState()

	// Obtener datos de abonos
	const [abonos] = useTraeDatos(ApiPaths.operadoresAbonos, { idOperador: user?.operadorId })

	// Función para determinar el estado de la reserva
	const Estados = (dato) => {
		if (dato === 'PENDIENTE') {
			return 'P'
		} else if (dato === 'APROBADO') {
			return 'A'
		} else if (dato === 'RECHAZADO') {
			return 'R'
		} else if (dato === 'CANCELADO') {
			return 'C'
		} else if (dato === 'COMPLETADO') {
			return 'C'
		}
	}

	// Definir las columnas de la tabla
	const columns = [
		{
			title: 'Estado',
			render: (item) => <span className={`estados ${item.estado}`}>{Estados(item.estado)}</span>,
			width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "estado"
		},
		{
			title: 'Cod', render: (item) => `${item.codigoReserva}`,
			width: "10%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "codigoReserva"
		},
		{
			title: "Creación", field: "fechaReserva", width: "17%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Ingreso", field: "tipoingreso", width: "30%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Fecha Reserva", render: (item) => <>{item.fechaReservaInicial} {item.fechaReservaFinal && `- ${item.fechaReservaFinal}`}</>,
			width: "22%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "fechaReservaInicial"
		},
		{
			title: "Total", render: (item) => <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} />, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Fecha Cancelación", field: "fechaReservaCancelacion", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Saldo", render: (item) => {
				if (item.saldos.trim() !== '') {
					const saldos = JSON.parse(decodeURI(item?.saldos))
					return (<NumericFormat displayType="text" value={saldos.saldoReserva} prefix={'$'} thousandSeparator={true} />)
				}
			}, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Operador", field: "operador", width: "15%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Metodo Pago",
			field: "transaccionesMetodoPago",
			width: "15%",
			cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			hidden: parseInt(user.roll) != TAQUILLA,
		}
	];

	// Función para calcular la diferencia de días
	const diferenciaDias = (fecha, fechaInicial, saldos) => {
		let saldosTotal = saldos.trim() !== '' ? JSON.parse(decodeURI(saldos)) : ''

		if (parseInt(user.roll) === TAQUILLA) return false;
		if (parseInt(user.roll) === VISITANTE) return false;
		if (parseInt(user.roll) === SUPERADMIN) return true;

		//	const restriccionDias = parseInt(process.env.REACT_APP_SECRET_RESTRICCION_DIAS_RESERVA_EDITAR)
		//const restriccionDiasCreacion = parseInt(process.env.REACT_APP_SECRET_RESTRICCION_DIAS_RESERVA_EDITAR_CREACION)
		//const fechaReserva = moment(fecha).add(restriccionDias, 'd');
		const fechaReservaInicial = moment(fechaInicial);
		const fechaHoy = moment(moment().format("YYYY-MM-DD"));
		//const diferenciaDiasReserva = fechaReserva.diff(fechaHoy, 'days')
		const diferenciaDiasReservaInicial = fechaReservaInicial.diff(fechaHoy, 'days')

		//	console.log(diferenciaDiasReservaInicial, 'diferenciaDiasReservaInicial')
		if (diferenciaDiasReservaInicial <= 0) {
			return false;
		}

		/*if (diferenciaDiasReserva <= 0) {
			return false;
		}*/

		// if (saldosTotal?.editar) {
		// 	return false;
		// }

		return true;
	}

	// Función para realizar el pago de una reserva
	const pagarReserva = async (IdReserva, saldoReserva, IdTransaccion) => {
		let date = new Date();
		const pm_token = generateToken(date); // Reemplaza esto con tu token real

		try {
			const response = await axios({
				url: API_URL_DEV,
				method: 'POST', // Cambia a POST, PUT, etc. según sea necesario
				headers: {
					'Content-Type': 'application/json',
					'auth-token': pm_token,
				},
				data: {
					user: {
						id: IdTransaccion,
						email: user.correo,
						name: user.operador,
						last_name: user.nombre
					},
					order: {
						dev_reference: IdReserva,
						description: "Reserva",
						amount: saldoReserva,
						installments_type: 0,
						currency: "COP"
					},
					configuration: {
						partial_payment: true,
						expiration_days: 1,
						allowed_payment_methods: ["All"],
						success_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_APROBADO,
						failure_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_RECHAZADO,
						pending_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE,
						review_url: baseUrlGeneral + RUTAS.ESTADO_TRANSACCION_PENDIENTE
					}
				},
			});

			let UrlPago = response.data.data.payment.payment_url

			window.location.href = UrlPago;

			console.error('response.data', response.data);


		} catch (error) {
			console.error('Hubo un error con la solicitud:', error);
		}
	};

	// Función para generar el token de autenticación
	const generateToken = (date) => {
		let server_application_code = API_LOGIN_DEV;
		let server_app_key = API_KEY_DEV;
		let unix_timestamp = Math.floor(date.getTime() / 1000);
		let uniq_token_string = server_app_key + unix_timestamp;
		let uniq_token_hash = CryptoJS.SHA256(uniq_token_string).toString();
		let auth_token = btoa(server_application_code + ";" + unix_timestamp + ";" + uniq_token_hash);
		// console.log('unix_timestamp', unix_timestamp);
		// console.log('uniq_token_string', uniq_token_string);
		// console.log('uniq_token_hash', uniq_token_hash);
		// console.log('auth_token', auth_token);

		return auth_token;
	};

	// Definir las acciones de la tabla
	const actions = [
		(item) => item.estado == "PENDIENTE" && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faDollarSign} size="lg" /></div>,
			tooltip: "Pagar",
			onClick: (event, rowData) => handleOnClick(rowData.id, rowData.saldos, rowData.id_transaccion, rowData.idOperador, rowData.id_usuario, rowData.codigoReserva, rowData.total),

		}),
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEye} size="lg" /></div>,
			tooltip: "Ver",
			onClick: (event, rowData) => seleccionaRegistro(item, 'detalle'),

		}), (item) => ((diferenciaDias(item.fechaReserva, item.fechaReservaInicial, item.saldos) && item.estado !== 'CANCELADO') || user.roll === '0' && item.estado !== 'CANCELADO') && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faPencil} size="lg" /></div>,
			tooltip: "Editar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'editar'),

		}), (item) => item.estado == 'PENDIENTE' && item.modificacionesReserva == 'true' && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faFilePen} size="lg" /></div>,
			tooltip: "Ver Reserva Editada",
			onClick: (event, rowData) => seleccionaRegistro(item, 'editardetalle'),

		}),
		(item) => diferenciaDias(item.fechaReserva, item.fechaReservaInicial, item.saldos) && item.estado !== 'CANCELADO' && ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faX} size="lg" /></div>,
			tooltip: "Cancelar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'cancelar'),
		}),
		parseInt(user.roll) === SUPERADMIN && ({
			icon: () => <FontAwesomeIcon icon={faCloudArrowUp} size='sm' className='icon-btn' />,
			tooltip: "Backup",
			onClick: (event, rowData) => exportarCSV(),
			isFreeAction: true,
		})
	]

	// Manejar el clic en una acción
	const handleOnClick = (id, saldos, id_transaccion, idoperador, idusuario, codigoreserva, total) => {

		let saldosTotal = saldos ? JSON.parse(decodeURI(saldos)) : null;

		if (abonos?.saldo_total > 0 && saldosTotal?.saldoReserva > 0) {
			const saldoTotalAbonos = parseInt(abonos?.saldo_total)

			const saldoPendiente = (saldosTotal?.saldoReserva - saldoTotalAbonos) > 0 ? saldosTotal?.saldoReserva - saldoTotalAbonos : 0;

			const saldoPendienteFormato = saldoPendiente.toLocaleString('es-CO', {
				style: 'currency', currency: 'COP',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			});



			const saldoTotalAbonosFormato = saldoTotalAbonos.toLocaleString('es-CO', {
				style: 'currency', currency: 'COP',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0
			});

			setAlertaTexto(`El pago se realizara con el saldo a favor de ${saldoTotalAbonosFormato}, quedando un saldo por pagar de ${saldoPendienteFormato}. ¿Desea continuar con el pago ?`)
			setMostrarAlerta(true);

			const saldoaFavor = saldoTotalAbonos;
			const totalFacturado = saldosTotal?.saldoReserva;

			let saldoRestante = 0;
			let abonoUtilizado = 0;

			if (saldoaFavor >= totalFacturado) {
				saldoRestante = 0;
				abonoUtilizado = totalFacturado;
			} else {
				saldoRestante = totalFacturado - saldoaFavor;
				abonoUtilizado = saldoaFavor;
			}

			setSaldosPagar({
				id,
				id_transaccion,
				abonos: true,
				saldoRestante,
				abonoUtilizado,
				totalFacturado,
				idoperador,
				idusuario,
				codigoreserva,
				saldoPendiente
			})
			return;
		} else {
			pagarReserva(id, saldosTotal ? saldosTotal?.saldoReserva : total, id_transaccion)

		}
	}

	// Manejar la aceptación de la alerta
	const handleAceptar = async () => {
		const saldos = {
			nuevoTotalReserva: saldosPagar.totalFacturado,
			saldoReserva: saldosPagar.saldoRestante,
			totalReservaAnterior: 0,
			editar: false
		};

		if (saldosPagar.abonos) {
			const estado = saldosPagar.saldoRestante === 0 ? 'APROBADO' : 'PENDIENTE'
			let formData = new FormData()
			formData.append('fechaReserva', moment().format("YYYY-MM-DD HH:mm:ss"))
			formData.append('estadoTransaccion', estado)
			formData.append('estado', estado)
			formData.append('saldos', encodeURI(JSON.stringify(saldos)))
			formData.append('id', saldosPagar.id)
			formData.append('pagaconabonos', 'true')
			formData.append('abonoTotalUtilizado', saldosPagar.abonoUtilizado)
			formData.append('idOperador', saldosPagar.idoperador)
			formData.append('id_operador', saldosPagar.idoperador)
			formData.append('id_usuario', saldosPagar.idusuario)
			formData.append('saldoPendiente', saldosPagar.saldoPendiente)
			formData.append('codigoReserva', saldosPagar.codigoreserva)
			formData.append('METHOD', 'PUT')

			await axios.post(ApiPaths.reservas, formData)

			if (saldosPagar.saldoRestante != 0) {
				pagarReserva(saldosPagar.id, saldosPagar.saldoRestante, saldosPagar.id_transaccion)
			} else {
				toast.success('Reserva pagada con exito')
				window.location.reload();
			}
		}
		//pagarReserva(saldosPagar.id, saldosPagar.saldoRestante, saldosPagar.id_transaccion)
		// Lógica adicional para aceptar la acción
	}

	// Manejar la cancelación de la alerta
	const handleCancelar = () => {
		console.log('Acción cancelada');
		setSaldosPagar()
		// Lógica adicional para cancelar la acción
	}
	return (
		<div>
			<div className='totales-reservas'>
				{/* Mostrar totales de reservas, saldo pendiente y saldo a favor */}
				<div className='total-reservas container-totales-reservas'>
					<p>Total <b>Reservas</b></p>
					<NumericFormat displayType="text" value={abonos?.total_reservas} prefix={'$'} thousandSeparator={true} />
				</div>

				<div className='total-saldo-pendiente container-totales-reservas'>
					<p>Total <b>Saldo Pendiente</b></p>
					<NumericFormat displayType="text" value={abonos?.saldo_pendiente} prefix={'$'} thousandSeparator={true} />
				</div>

				<div className='total-saldo-favor container-totales-reservas'>
					<p>Total <b>Saldo Favor</b></p>
					<NumericFormat displayType="text" value={abonos?.saldo_total} prefix={'$'} thousandSeparator={true} />
				</div>
			</div>
			{/* Renderizar la tabla de reservas */}
			<Tabla columns={columns} data={reservas} actions={actions} title='Listado de Reservas' />
			{/* Mostrar alerta de confirmación */}
			<AlertaConfirmacion
				mostrar={mostrarAlerta}
				setMostrar={setMostrarAlerta}
				onAceptar={handleAceptar}
				onCancelar={handleCancelar}
				textoinformativo={alertaTexto}
			/>
		</div>
	)
}

export default VerReservas