import React, { useState, useEffect, useContext } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import axios from 'axios'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'
import { errores } from '../hooks/Funciones'
import { Alert, Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import { toast } from 'react-toastify';

const SubirOperador = ({ baseUrl, setFuncion, operadores, setOperadores, Titulo, setTituloPag }) => {

	const [registro, setRegistro] = useState({ imagePrev: null })
	const [error, setError] = useState({})
	const location = useLocation();

	const peticionPost = (e) => {
		e.preventDefault()
		const fecha = moment(new Date()).format("YYYY-MM-DD-HH-mm-ss");

		let { nombre, representante, telefono, codigo, correo, direccion, ciudad, imagen } = registro


		let formato = imagen.type.replace('image/', '.');
		let RutaServ = 'images/'

		let formatosPermitidos = ['.png', '.jpeg', '.jpg']

		if (registro.nombre !== '' && registro.representante !== '' && imagen !== null && correo !== null) {
			if (imagen.size < 2000000 && formatosPermitidos.includes(formato)) {

				if (location.pathname === RUTAS.OPERADORES) {
					let f = new FormData()

					let NombreIma = RutaServ + fecha + formato
					f.append('nombre', nombre)
					f.append('representante', representante)
					f.append('telefono', telefono)
					f.append('codigo', codigo)
					f.append('correo', correo)
					f.append('direccion', direccion)
					f.append('ciudad', ciudad)
					f.append('imagen', imagen)
					f.append('nombre_imagen', NombreIma)
					f.append('activo', 'false')
					f.append('METHOD', 'POST')
					axios.post(baseUrl, f)
						.then(response => {
							errores('Limpio', setError)
							setRegistro({
								nombre: '',
								representante: '',
								telefono: '',
								codigo: '',
								correo: '',
								direccion: '',
								ciudad: '',
								imagen: '',
								imagePrev: null
							})
							toast.success('Operador creado con exito')
						}).catch(error => {
							console.log('muestra erro: ' + error);
							toast.error('Error al crear el operador')
						})

				} else {
					let f = new FormData()

					let NombreIma = RutaServ + fecha + formato
					f.append('nombre', nombre)
					f.append('representante', representante)
					f.append('telefono', telefono)
					f.append('codigo', codigo)
					f.append('correo', correo)
					f.append('direccion', direccion)
					f.append('ciudad', ciudad)
					f.append('imagen', imagen)
					f.append('nombre_imagen', NombreIma)
					f.append('activo', 'true')
					f.append('METHOD', 'POST')
					axios.post(baseUrl, f)
						.then(response => {
							let resultFormat = { ...response.data, imagePrev: NombreIma, imagen: NombreIma }
							setOperadores([resultFormat, ...operadores]);

							console.log('enviado')
							errores('Limpio', setError)
						}).catch(error => {
							console.log('muestra erro: ' + error);
						})
					setFuncion('ver')
				}

			} else errores('Imagen', setError)
		} else errores('CampoVacio')

	}

	const recibeImagen = e => {
		let picture = e.target.files[0]
		setRegistro({ ...registro, imagePrev: URL.createObjectURL(picture), imagen: picture })
	}


	return (
		<div>
			<Form onSubmit={peticionPost} >
				<MDBContainer>
					<MDBRow>
						<MDBCol>
							<hr />
							{error.activo ?
								<span className="error"> Error: {error.mensaje}</span> : null
							}

							<div className="row">
								<div className="col-md-9">

									<MDBInput label="Nombre Operador" outline
										value={registro.nombre}
										onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
										required
									/>

									<div className="input-group">
										<div className="input-group-prepend">
										</div>
										<div className="custom-file">
											<input type="file"
												className="custom-file-input"
												id="inputGroupFile01"
												aria-describedby="inputGroupFileAddon01"
												name="imagen"
												accept="image/png, image/gif, image/jpeg"
												onChange={recibeImagen}
												required
											/>
											<label className="custom-file-label">
												{registro.imagen ? registro.imagen?.name : 'Seleccionar Logo (400px x 400px)'}
											</label>
										</div>
									</div>

									<div className='row'>
										<div className='col-md-6'>
											<MDBInput label="Rnt" outline
												value={registro.codigo}
												onChange={e => setRegistro({ ...registro, codigo: e.target.value })}
												required
											/>
										</div>
										<div className='col-md-6'>
											<MDBInput label="Telefono Operador" outline
												value={registro.telefono}
												onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
												required
											/>
										</div>

									</div>

								</div>

								<div className="col-md-3">

									<div className="FotoPrev">
										<img src={registro.imagePrev !== null ? registro.imagePrev : fotoVacia} />
									</div>
								</div>

							</div>

							<div className="row sinMargen">
								<div className="col-md-6">
									<MDBInput label="Ciudad" outline
										value={registro.ciudad}
										onChange={e => setRegistro({ ...registro, ciudad: e.target.value })}
										required
									/>
								</div>
								<div className="col-md-6">
									<MDBInput label="Dirección" outline
										value={registro.direccion}
										onChange={e => setRegistro({ ...registro, direccion: e.target.value })}
										required
									/>
								</div>
								<div className="col-md-6">
									<MDBInput label="Correo Electrónico" outline
										value={registro.correo}
										onChange={e => setRegistro({ ...registro, correo: e.target.value })}
										type='email'
										required
									/>
								</div>
								<div className="col-md-6">
									<MDBInput label="Representante Legal" outline
										value={registro.representante}
										onChange={e => setRegistro({ ...registro, representante: e.target.value })}
										required
									/>
								</div>
							</div>

							<div className="text-center mt-4">
								<div className="col text-center">
									<button
										type='submit'
										className="btn boton_estandar"
									>Subir Operador
									</button>
								</div>
							</div>
						</MDBCol>
					</MDBRow>
				</MDBContainer>
			</Form>
		</div>
	)
}

export default SubirOperador