import React, { useEffect, useState, useContext, useRef } from 'react'
import VerReservas from './VerReservas'
import UserContext from '../../context/UserContext'
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faPlus } from '@fortawesome/free-solid-svg-icons'

import * as RUTAS from '../../constantes/rutas';
import { ApiPaths, KeysNuvei } from '../../utils';
import { ReservaProvider } from '../../context/ReservaContext'
import BusquedaFechas from '../componentes/BusquedaFechas'
import Header from '../componentes/Header';
import moment from 'moment';
import axios from 'axios'
import { useReactToPrint } from "react-to-print";
import ReservasPrint from '../html/ReservasPrint';
import Select from 'react-select'
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap'
import { ExportCsv } from '@material-table/exporters'
import CryptoJS from 'crypto-js';


const HistorialAdmin = () => {
	const navigate = useNavigate()

	const componentRef = useRef();
	const titulo = "Reservas";
	const [tituloPag, setTituloPag] = useState(titulo)
	const [id, setId] = useState()
	const [funcion, setFuncion] = useState('ver')
	const [startDate, setStartDate] = useState(new Date());
	const [finalFecha, setFinalFecha] = useState(new Date());
	const [reservas, setReservas] = useState([])
	const [loading, setLoading] = React.useState(false);
	const [text, setText] = useState("old boring text");
	const [reservasImprimirTotales, setReservasImprimirTotales] = useState({})
	const [operadores, setOperadores] = useState([])
	const [operador, setOperador] = useState()
	const { user } = useContext(UserContext);

	const [reservasPrint, setReservasPrint] = useState([])
	const [estados, setEstados] = useState()
	const [medioPagos, setMedioPagos] = useState([])
	const [metodoPago, setmetodoPago] = useState()

	useEffect(() => {
		const operadores = async () => {
			try {
				const operadores = await axios(ApiPaths.operadores, { params: { activo: true } });
				setOperadores(operadores.data);
			} catch (error) {
				setOperador([])
			}
		}
		operadores()

		const traerMediosPagos = async () => {
			try {
				const { data } = await axios(ApiPaths.metodosPago)
				setMedioPagos(data)
			} catch (error) {
				setMedioPagos([])
				console.log(error)
			}
		}
		traerMediosPagos()
	}, [])


	useEffect(() => {
		peticionTraeDatos()
	}, [startDate, finalFecha, operador, estados, metodoPago])

	const peticionTraeDatos = async () => {
		try {
			if (parseInt(user.roll) === TAQUILLA) {
				const resp = await axios.get(ApiPaths.historialreservas, { params: { medioPagos: metodoPago, idTipoIngreso: 2, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), estados } })
				return setReservas(resp.data)
			}
			if (parseInt(user.roll) === VISITANTE) {
				const resp = await axios.get(ApiPaths.historialreservas, { params: { IdVisitante: user.id, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), estados } })
				return setReservas(resp.data)
			}
			if (parseInt(user.roll) != SUPERADMIN) {
				const resp = await axios.get(ApiPaths.historialreservas, { params: { idUsuario: user.id_operador, startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), estados } })
				return setReservas(resp.data)
			}


			const resp = await axios.get(ApiPaths.historialreservas, { params: { startDate: moment(startDate).format("YYYY-MM-DD"), finalFecha: moment(finalFecha).format("YYYY-MM-DD"), operador, estados } })

			setReservas(resp.data)
		} catch (error) {
			setReservas([])
			return error
		}

	}

	// TODO: want to make this `null` default but TS complains
	const onBeforeGetContentResolve = React.useRef(Promise.resolve);

	const handleAfterPrint = React.useCallback(() => {
		console.log("`onAfterPrint jj` called"); // tslint:disable-line no-console
	}, []);

	const handleBeforePrint = React.useCallback(() => {
		console.log("`onBeforePrint` called"); // tslint:disable-line no-console
	}, []);

	const handleOnBeforeGetContent = React.useCallback(() => {
		console.log("`onBeforeGetContent` called"); // tslint:disable-line no-console

		setLoading(true);
		setText("Loading new text...");

		return new Promise((resolve) => {
			onBeforeGetContentResolve.current = resolve;
			setTimeout(() => {
				setLoading(false);
				setText("New, Updated Text!");
				resolve();
			}, 500);
		});
	}, [setLoading, setText]);

	const reactToPrintContent = React.useCallback(() => {
		return componentRef.current;
	}, [componentRef.current]);

	const handlePrint = useReactToPrint({
		content: reactToPrintContent,
		documentTitle: `Reserva - ${new Date()}`,
		onBeforeGetContent: handleOnBeforeGetContent,
		onBeforePrint: handleBeforePrint,
		onAfterPrint: handleAfterPrint,
		removeAfterPrint: true
	});

	React.useEffect(() => {
		if (text === "New, Updated Text!" && typeof onBeforeGetContentResolve.current === "function") {
			onBeforeGetContentResolve.current();
		}
	}, [onBeforeGetContentResolve.current, text]);

	const imprimir = () => {
		try {
			let totalServicioEntradas = 0;
			let totalServicioGuia = 0;
			let totalServicioHospedaje = 0;
			let totalServicioAlimentacion = 0;
			let totalServicioVehiculo = 0;
			let totalReservas = 0;
			const reservaArray = []
			reservas && reservas.map(reserva => {
				if (reserva.estado === 'COMPLETADO' || reserva.estado === 'APROBADO') {
					totalServicioGuia += parseInt(reserva.totalServicioGuia)
					totalServicioHospedaje += parseInt(reserva.totalServiciosGenericos)
					totalServicioAlimentacion += parseInt(reserva.totalServicioAlimentacion)
					totalServicioEntradas += parseInt(reserva.totalEntradas)
					totalServicioVehiculo += parseInt(reserva.totalServicioVehiculo)
					totalReservas += parseInt(reserva.total)
					reservaArray.push(reserva)
				}

			})
			setReservasPrint(reservaArray)
			setReservasImprimirTotales({
				totalServicioAlimentacion,
				totalServicioGuia,
				totalServicioHospedaje,
				totalServicioEntradas,
				totalServicioVehiculo,
				totalReservas
			})
			handlePrint()
		} catch (error) {
			console.log(error)
		}

	}

	const seleccionaRegistro = async (...args) => {
		try {
			if (args[1] === "detalle") {
				navigate(args[0].id)
			}

		} catch (error) {
			console.log(error)
		}
	}


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} >
				<button
					onClick={imprimir}
					className="btn boton_estandar_blanco float-right"
				><FontAwesomeIcon icon={faClipboardList} size="lg" />
				</button>

				<BusquedaFechas
					startDate={startDate}
					setStartDate={setStartDate}
					finalFecha={finalFecha}
					setFinalFecha={setFinalFecha}
				/>
				{parseInt(user.roll) === SUPERADMIN && <div className='d-flex select-container '>
					<Select
						clearValue={() => 0}
						isClearable
						name="color"
						placeholder="Operador"
						options={operadores && operadores.map(item => ({ label: item.nombre, value: item.id }))}
						className="react-select-container"
						classNamePrefix="react-select"
						onChange={(e) => e ? setOperador(parseInt(e.value)) : setOperador(null)}
					/>
				</div>}
				<div className='d-flex select-container '>
					<Select
						clearValue={() => 0}
						isClearable
						placeholder="Estado"
						options={[{ label: 'PENDIENTE', value: 'PENDIENTE' }, { label: 'APROBADO', value: 'APROBADO' }, { label: 'RECHAZADO', value: 'RECHAZADO' }, { label: 'CANCELADO', value: 'CANCELADO' }, { label: 'COMPLETADO', value: 'COMPLETADO' }]}
						className="react-select-container ml-1"
						classNamePrefix="react-select"
						onChange={(e) => e ? setEstados(e.value) : setEstados(null)}
					/>
				</div>
				{parseInt(user.roll) === TAQUILLA &&
					<div className='d-flex select-container '>
						<Select
							clearValue={() => 0}
							isClearable
							placeholder="Metodo Pago"
							options={medioPagos && medioPagos.map(item => ({
								value: item.nombre, label: item.nombre
							}))}
							className="react-select-container ml-1"
							classNamePrefix="react-select"
							onChange={(e) => e ? setmetodoPago(e.value) : setmetodoPago(null)}
						/>
					</div>
				}
			</Header>


			{funcion === 'ver' &&
				<VerReservas reservas={reservas} seleccionaRegistro={seleccionaRegistro} />
			}

			<div style={{ display: 'none' }}> <ReservasPrint ref={componentRef} data={reservasPrint} reservasImprimirTotales={reservasImprimirTotales} /></div>

		</div>
	)
}

export default HistorialAdmin