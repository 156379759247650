import React, { useContext, useEffect, useMemo, useState } from 'react'
import Tabla from '../componentes/Tabla';
import { NumericFormat } from 'react-number-format';
import { ApiPaths } from '../../utils';
import moment from 'moment';
import useTraeDatos from "../hooks/useTraeDatos";
import UserContext from '../../context/UserContext';
import { SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faCalendar, faCalendarDays, faEye } from '@fortawesome/free-solid-svg-icons';
import * as RUTAS from '../../constantes/rutas';
import axios from 'axios'

const VerReservasDia = () => {
	const navigate = useNavigate()

	const { user } = useContext(UserContext);
	const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
	const [data, setData] = useState([])
	const [tiposIngresos, setTiposIngresos] = useState([])

	useEffect(() => {
		const fechData = async () => {
			try {
				const resp = await axios(ApiPaths.informes, { params: { fecha: startDate } })
				setData(resp.data);

				const tiposIngresoAgrupados = Object.values(
					resp.data.reduce((acc, curr) => {
						if (curr.estado != "CANCELADO" && curr.estado != "PENDIENTE") {
							const tipo = curr.tipoingreso;
							if (acc[tipo]) {
								acc[tipo].capacidadActual += parseInt(curr.entradas);
							} else {
								acc[tipo] = {
									nombre: curr.tipoingreso,
									capacidadActual: parseInt(curr.entradas),
									capacidadLimite: parseInt(curr.capacidadlimite),
									hospedaje: false
								};
							}
						}
						return acc;
					}, {})
				);

				const hospedajeAgrupado = Object.values(
					resp.data.reduce((acc, curr) => {
						if (curr.estado != "CANCELADO" && curr.estado != "PENDIENTE") {
							if (acc["Hospedaje"]) {
								acc["Hospedaje"].capacidadActual += Number(curr.cantidadServiciosGenericos);
							} else {
								acc["Hospedaje"] = {
									nombre: "Hospedaje",
									capacidadActual: Number(curr.cantidadServiciosGenericos),
									hospedaje: true,
									capacidadLimite: parseInt(curr.limiteServicios),
								};
							}
						}
						return acc;
					}, {})
				);


				setTiposIngresos([...tiposIngresoAgrupados, ...hospedajeAgrupado])

				console.log('tiposIngresoAgrupados', tiposIngresoAgrupados)
				console.log('hospedajeAgrupado', hospedajeAgrupado)
			} catch (error) {
				setData([])
				console.log(error)
			}
		}
		fechData()
	}, [startDate])


	const Estados = (dato) => {
		if (dato === 'PENDIENTE') {
			return 'P'
		} else if (dato === 'APROBADO') {
			return 'A'
		} else if (dato === 'RECHAZADO') {
			return 'R'
		} else if (dato === 'CANCELADO') {
			return 'C'
		} else if (dato === 'COMPLETADO') {
			return 'C'
		}
	}
	const columns = [
		{
			title: 'Estado',
			render: (item) => <span className={`estados ${item.estado}`}>{Estados(item.estado)}</span>,
			width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "estado"
		},
		{
			title: 'Cod', render: (item) => `${item.codigoReserva}`,
			width: "10%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "codigoReserva"
		},
		{
			title: "Creación", field: "fechaReserva", width: "17%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Ingreso", field: "tipoingreso", width: "30%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Reserva", render: (item) => <>{item.fechaReservaInicial} {item.fechaReservaFinal && `- ${item.fechaReservaFinal}`}</>,
			width: "22%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "fechaReservaInicial"
		},
		{
			title: "Cupos", width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "entradas"

		},
		{
			title: "Hospedaje", width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "cantidadServiciosGenericos"

		},
		{
			title: "Total", render: (item) => <NumericFormat displayType="text" value={item.total} prefix={'$'} thousandSeparator={true} />, width: "3%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			field: "total"

		},
		{
			title: "Operador", field: "operador", width: "15%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Metodo Pago",
			field: "transaccionesMetodoPago",
			width: "15%",
			cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
			hidden: parseInt(user.roll) != TAQUILLA,
		}
	];
	const actions = [
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEye} size="lg" /></div>,
			tooltip: "Ver",
			onClick: (event, rowData) => navigate(`${RUTAS.ADMIN_RESERVA}/${item.id}`, { state: { reservas_dia: true } }),

		})
	]

	return (
		<div>

			<div className='totales-reservas'>
				<div>
					<label htmlFor="start">Fecha:</label>
					<input
						className='btn boton_estandar'
						type="date"
						value={startDate}
						onChange={e => setStartDate(e.target.value)}
					/>
				</div>

				{tiposIngresos.map((item) => {
					const porcentajeOcupacion = (item.capacidadActual / item.capacidadLimite) * 100;

					let claseOcupacion = "";
					if (porcentajeOcupacion >= 100) {
						claseOcupacion = "Rojo";
					} else if (porcentajeOcupacion >= 65) {
						claseOcupacion = "Amarillo";
					} else {
						claseOcupacion = "Blanco";
					}
					return (
						<div className={`total-reservas container-reservas-totales ${claseOcupacion}`}>
							<p><b>{item.nombre}</b></p>
							<div className='d-flex align-items-center'>
								{item.hospedaje ?
									<>
										<FontAwesomeIcon icon={faBed} className='mr-1' /> <p>{item.capacidadActual} de {item.capacidadLimite}</p>
									</>
									:
									<>
										<FontAwesomeIcon icon={faCalendarDays} className='mr-1' /> <p>{item.capacidadActual} de {item.capacidadLimite}</p>
									</>
								}
							</div>
						</div>
					)
				})}
			</div>
			<Tabla columns={columns} data={data} actions={actions} title='Listado de Reservas del día' />
		</div>
	)
}

export default VerReservasDia