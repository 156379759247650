import { faBinoculars, faTaxi, faUser, faVanShuttle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NumericFormat } from 'react-number-format'

const InformacionServicios = ({ reservaSeleccionada }) => (
    <div className='columaReserva'>
        <h5><FontAwesomeIcon icon={faTaxi} size="1x" style={{ marginRight: 0 }} /> Servicios:</h5>
        <hr></hr>
        {reservaSeleccionada.servicioVehiculo ?
            <div>
                {
                    reservaSeleccionada.servicioVehiculo ? reservaSeleccionada.servicioVehiculo.map((item, index) => item.cantidad > 0 &&
                        <div key={index} className='mb-2'>
                            <b><FontAwesomeIcon icon={faVanShuttle} size="1x" style={{ marginRight: 0 }} /> Alquiler de {item.nombre}</b>
                            <p><b>Capacidad: </b>{item.capacidad}</p>
                            <p><b>Cantidad: </b>x{item.cantidad} </p>
                            <p><b>Tarifa: </b><NumericFormat displayType="text" value={item.tarifa} prefix={'$'} thousandSeparator={true} /> </p>
                        </div>
                    )
                        : null
                }
                <p><b>Total: </b><NumericFormat displayType="text" value={reservaSeleccionada.totalServicioVehiculo} prefix={'$'} thousandSeparator={true} /> </p>
                <hr></hr>
            </div>
            : null
        }

        {reservaSeleccionada.servicioGuia ?
            <div>
                <b><FontAwesomeIcon icon={faBinoculars} size="1x" style={{ marginRight: 0 }} /> Alquiler de servicio de guia</b>
                <p><b>Cantidad: </b>x{reservaSeleccionada.servicioGuia.length} </p>
                <p><b>Total: </b><NumericFormat displayType="text" value={reservaSeleccionada.totalServicioGuia} prefix={'$'} thousandSeparator={true} /> </p>
                <hr></hr>
            </div> : null
        }
    </div>
)

export default InformacionServicios