import React, { useEffect, useState } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import useTraeDatos from '../hooks/useTraeDatos';
import { traeIdNombre, existeRegistro, recibeImagen, errores } from '../hooks/Funciones'
import fotoVacia from '../../paginas/img/fotoVacia.jpg'
import moment from 'moment'


const SubirEditTarifas = ({ baseUrl, data, setData, setFuncion, urlIngresos, registroSelect, TituloBtn, funcion }) => {

	const [registro, setRegistro] = useState(funcion === 'editar' ? registroSelect : { activo: true, ids_tipoingresos: [], imagePrev: fotoVacia })
	const [tiposIngresos] = useTraeDatos(urlIngresos)
	const [error, setError] = useState({ activo: false })
	const [imagenOld, setImagenOld] = useState(registro.imagen)

	//Consulta para subir o crear los datos 

	const creaFormData = (registro, METHOD, NombreIma) => {

		let { id, nombre, tipoingreso, edadinicial, edadfinal, tarifa, nacionalidad } = registro

		let f = new FormData()
		f.append('id', id)
		f.append('nombre', nombre)
		f.append('tipoingreso', tipoingreso)
		f.append('edadinicial', edadinicial)
		f.append('edadfinal', edadfinal)
		f.append('tarifa', tarifa)
		f.append('nacionalidad', nacionalidad)
		f.append('id_tipoingreso', traeIdNombre(tiposIngresos, tipoingreso))
		f.append('METHOD', METHOD)

		return f
	}

	const peticionPost = async () => {

		let { nombre, tipoingreso, edadinicial, edadfinal, tarifa, nacionalidad } = registro

		if (!existeRegistro(data, nombre)) {
			if (nombre !== undefined && tipoingreso !== undefined && edadinicial !== undefined && edadfinal !== undefined && tarifa !== undefined && nacionalidad !== undefined) {

				let f = creaFormData(registro, 'POST')
				await axios.post(baseUrl, f)

					.then(response => {
						console.log('result', response.data)
						let resultFormat = { ...response.data }
						setData([resultFormat, ...data])
						setRegistro({ activo: false })
						errores('Limpio', setError)
						setFuncion('ver')

					}).catch(error => {
						console.log(error);
					})
			} else errores('CampoVacio', setError)
		} else errores('Existe', setError)

	}

	const ProcedimientoPut = async () => {

		//let {id, nombre, cedula, runt, telefono, ids_tipoingresos, imagen, imagenNueva, nombreImaNueva} = registro
		let { id, nombre, tipoingreso, edadinicial, edadfinal, tarifa, nacionalidad } = registro

		let f = creaFormData(registro, 'PUT')
		await axios.post(baseUrl, f)

			.then(response => {

				console.log(response.data)
				let dataNueva = data
				dataNueva.map(item => {
					if (item.id === id) {
						item.nombre = nombre
						item.tipoingreso = tipoingreso
						item.edadinicial = edadinicial
						item.edadfinal = edadfinal
						item.tarifa = tarifa
						item.nacionalidad = nacionalidad
					}
				})
				setData(dataNueva)
				console.log('enviado')
				errores('Limpio', setError)
			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	const peticionPut = () => {

		let { nombre, tipoingreso, edadinicial, edadfinal, tarifa, nacionalidad } = registro
		if (nombre !== '' && tipoingreso !== '' && edadinicial !== '' && edadfinal !== '' && tarifa !== '' && nacionalidad !== '') {
			ProcedimientoPut()
		} else errores('CampoVacio', setError)
	}

	const estaonoArray = (dato) => {
		let array = registro.ids_tipoingresos
		let result = false
		array.map(item => { if (item === dato) { result = true } })
		return result
	}

	const subeIngresos = (id) => e => {
		let array = registro.ids_tipoingresos
		if (estaonoArray(id)) {
			let index = array.indexOf(id);
			if (index > -1) { array.splice(index, 1); }
		} else { array = [...array, id] }

		setRegistro({ ...registro, ids_tipoingresos: array })

	}


	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ? <span className="error"> Error: {error.mensaje}</span> : null}
					<div><hr />

						<div className='row'>
							<div className='col-md-12'>
								<MDBInput
									label="Nombre de la Tarifa" name="nombre" required className={error.nombre ? "form-control is-invalid" : null} outline
									value={registro.nombre}
									onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
								/>

								<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
									value={registro.tipoingreso}
									onChange={e => setRegistro({ ...registro, tipoingreso: e.target.value })}>
									<option value={""}>Selecciona Ingreso</option>
									{tiposIngresos.map((item, index) => {
										if (item.tipo === "Ingreso") {
											return (<option key={index} value={item.nombre}>{item.nombre}</option>)
										}
									})}
								</select>


								<div className="row">
									<div className='col-md-4'>
										<MDBInput label="Edad Mínima" name="edadinicial" required className={error.campoClave ? "form-control is-invalid" : null} outline
											value={registro.edadinicial}
											onChange={e => setRegistro({ ...registro, edadinicial: e.target.value })}
										/>
									</div>
									<div className='col-md-4'>
										<MDBInput label="Edad Máxima" name="edadfinal" required className={error.campoClave ? "form-control is-invalid" : null} outline
											value={registro.edadfinal}
											onChange={e => setRegistro({ ...registro, edadfinal: e.target.value })}
										/>
									</div>
									<div className='col-md-4'>
										<MDBInput label="Tarifa" name="tarifa" required className={error.campoClave ? "form-control is-invalid" : null} outline
											value={registro.tarifa}
											onChange={e => setRegistro({ ...registro, tarifa: e.target.value })}
										/>
									</div>
								</div>

								<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
									value={registro.nacionalidad}
									onChange={e => setRegistro({ ...registro, nacionalidad: e.target.value })}>
									<option value={""}>Selecciona Nacionalidad</option>
									<option value={"Nacional"}>Nacional</option>
									<option value={"Extranjero"}>Extranjero</option>
								</select>

							</div>
						</div>

						<br />
						<div className="col text-center">
							<button onClick={funcion === 'editar' ? peticionPut : peticionPost} className="btn boton_estandar">{TituloBtn} </button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SubirEditTarifas