import React, { useContext, useEffect, useState } from 'react'
import { MDBBtn, MDBInput, MDBInputGroup } from 'mdbreact';
import { uid } from 'uid';
import UserContext from '../../context/UserContext';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import axios from 'axios';
import { ApiPaths } from '../../utils';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { AlertaConfirmacion } from '../componentes/AlertaConfirmacion';
import { SubirPersonasCSV } from '../componentes/SubirPersonasCSV';
import moment from 'moment';

const CampoPersonas = ({ serviciosTotal, actualizarServiciosGeneralesTotal, errors, value, updateServicioTotal }) => {
    const [error, setError] = useState({ activo: false });
    const [personas, setPersonas] = useState([])
    const { user } = useContext(UserContext);
    const [mostrar, setMostrar] = useState(false);
    const [persona, setPersona] = useState()

    // Verifica si los campos requeridos están llenos
    const verificarEstado = () => {
        let camposLlenos = 0;
        const contarCamposPersonas = 8 * personas.length;
        const calcularCantidadRequerida = Math.round((contarCamposPersonas * parseInt(process.env.REACT_APP_SECRET_RESTRICCION_PORCENTAJE_CAMPOS_REQUERIDOS_MINIMOS)) / 100);

        personas.map(persona => {
            if (persona?.numeroDocumento?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.primerApellido?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.segundoApellido?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.nombre?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.segundoNombre?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.sexo?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.fechaNacimiento?.trim() !== "") {
                camposLlenos += 1;
            }
            if (persona?.tipoDocumento?.trim() !== "") {
                camposLlenos += 1;
            }


        })
        if (serviciosTotal.id) {
            return true
        } else {
            return camposLlenos < calcularCantidadRequerida;
        }
    }

    // Fecha máxima de nacimiento permitida
    const fechaActual = new Date(new Date().getFullYear(), 0, 1);
    const maxFechaNacimiento = new Date(fechaActual.getFullYear() - 69, fechaActual.getMonth(), fechaActual.getDate())
        .toISOString()
        .split('T')[0];


    // Maneja los cambios en los inputs
    const handlerInputChange = (e, key, idx) => {
        let { ...currentPersona } = personas[idx];
        currentPersona = { ...currentPersona, [key]: e?.target?.value ? e?.target?.value : typeof e === 'string' ? e : e instanceof File ? e : "" };
        personas[idx] = currentPersona;

        const newPersonas = Array.from(personas);

        setPersonas(newPersonas);

        if (key === 'fechaNacimiento') {
            const fechaIngresada = new Date(e.target.value);
            const fechaMin = new Date(maxFechaNacimiento);
            const fechaMax = fechaActual;
            if (fechaIngresada < fechaMin || fechaIngresada > fechaMax) {
                updateServicioTotal({ ...serviciosTotal, botonHabilitado: false })
                setError({ ...error, fechaNacimiento: true })
                return;
            } else {
                setError({ ...error, fechaNacimiento: false })
                updateServicioTotal({ ...serviciosTotal, botonHabilitado: true })
            }
        }
        actualizarServiciosGeneralesTotal(newPersonas)


    }

    // Efecto para actualizar las personas cuando cambia el valor
    useEffect(() => {
        if (value) {
            if (serviciosTotal.entradas == value?.length) {
                return setPersonas(value)
            }

            // Filtrar nacionalidades 
            const arrayPersonas = value;
            serviciosTotal.tipoEntradas.map((tipoEntrada, index) => {
                // Servicios Creados Total

                const contarServicioCreados = value.filter(tipo => tipoEntrada.id == tipo.idTipoEntrada).length

                // Si los servicios creados no coinciden

                if (tipoEntrada.contador && contarServicioCreados != tipoEntrada.contador || tipoEntrada.contador && contarServicioCreados != (tipoEntrada.contador == 0) || contarServicioCreados > tipoEntrada.contador) {
                    // Se valida la diferencia 
                    const diferencia = tipoEntrada.contador - contarServicioCreados;
                    //console.log(index, 'hola', diferencia)
                    if (diferencia <= 0) {

                        let contadorEliminar = 0;

                        const numeroEliminar = Math.abs(diferencia);
                        value.map((item, index) => {
                            if (tipoEntrada.id == item.idTipoEntrada) {
                                if (numeroEliminar != contadorEliminar) {
                                    arrayPersonas.splice(index, 1)
                                    contadorEliminar++
                                }
                            }
                        })
                    } else {
                        for (let index = 0; index < diferencia; index++) {
                            arrayPersonas.push({
                                numeroDocumento: '',
                                primerApellido: '',
                                segundoApellido: '',
                                nombre: '',
                                segundoNombre: '',
                                sexo: '',
                                fechaNacimiento: '',
                                tipoDocumento: '',
                                aseguradora: user && parseInt(user.roll) === TAQUILLA ? 'Seguro comprado en Taquilla' : user ? '' : '',
                                codigoSeguro: user && parseInt(user.roll) === TAQUILLA ? 'Seguro comprado en Taquilla' : user ? '' : '',
                                idTipoEntrada: tipoEntrada.id,
                                tipoEntrada: tipoEntrada.nombre,
                                nacionalidad: tipoEntrada.nacionalidad,
                                id: uid(15)
                            })
                        }
                    }
                }
            })
            setPersonas(arrayPersonas)
        } else {
            const personas = []
            serviciosTotal?.tipoEntradas?.map(tipoEntrada => {
                for (let index = 0; index < tipoEntrada.contador; index++) {
                    personas.push({
                        numeroDocumento: '',
                        primerApellido: '',
                        segundoApellido: '',
                        nombre: '',
                        segundoNombre: '',
                        sexo: '',
                        fechaNacimiento: '',
                        tipoDocumento: '',
                        aseguradora: user && parseInt(user.roll) === TAQUILLA ? 'Seguro comprado en Taquilla' : user && parseInt(user.roll) === VISITANTE ? '' : user ? '' : '',
                        codigoSeguro: user && parseInt(user.roll) === TAQUILLA ? 'Seguro comprado en Taquilla' : user && parseInt(user.roll) === VISITANTE ? '' : user ? '' : '',
                        idTipoEntrada: tipoEntrada.id,
                        tipoEntrada: tipoEntrada.nombre,
                        nacionalidad: tipoEntrada.nacionalidad,
                        id: uid(15)
                    })
                }
            })
            setPersonas(personas)
        }

    }, [value])

    // Bloquea los datos de la reserva si no es SUPERADMIN
    const bloquearDatosReserva = () => {
        if (user && parseInt(user.roll) === SUPERADMIN) return false;
        if (serviciosTotal.id) {
            return true
        }

    }
    const [selectedFile, setSelectedFile] = useState(null);

    // Maneja el cambio de archivo
    const handleFileChange = (e, name, indx) => {
        handlerInputChange(e.target.files[0], name, indx)
    };

    // Sube el archivo seleccionado
    const handleUpload = async (imagen, nombre, indx) => {
        if (imagen) {
            try {
                let formData = new FormData()
                formData.append('archivo', imagen)
                formData.append('METHOD', 'POST')
                const respuesta = await axios.post(ApiPaths.reservasImages, formData)
                personas.map((persona, index) => {
                    handlerInputChange(respuesta.data.ruta, nombre, index)
                })

                toast.success('Archivo subido con exito')
            } catch (error) {
                console.log(error)
            }

        } else {
            console.error('No se ha seleccionado ningún archivo.');
        }
    };

    // Elimina el archivo seleccionado
    const handleRemove = (nombre, indx) => {
        personas.map((persona, index) => {
            handlerInputChange('', nombre, index)
        })

    }

    // Elimina una persona de la lista
    const handleRemoveUser = (persona) => {
        let copiaServiciosTotal = serviciosTotal;
        const nuevaPersona = personas.filter(p => p.id !== persona.id);

        const entradaId = copiaServiciosTotal.tiposEntradas.data.find(entrada => entrada.id === persona.idTipoEntrada);

        entradaId.contador -= 1;
        copiaServiciosTotal.tiposEntradas.contador -= 1;
        const tarifa = parseInt(entradaId.tarifa);
        entradaId.total -= tarifa;
        copiaServiciosTotal.tiposEntradas.total -= tarifa
        copiaServiciosTotal.totalEntradas -= tarifa
        copiaServiciosTotal.entradas -= 1
        copiaServiciosTotal.seguroMedico -= 1
        copiaServiciosTotal.personas = nuevaPersona;
        updateServicioTotal(copiaServiciosTotal)

        setPersonas(nuevaPersona)
        actualizarServiciosGeneralesTotal(nuevaPersona)

    }

    // Muestra el modal de confirmación
    const toogleMostrar = (persona) => {
        setPersona(persona)
        setMostrar(true)
    }

    // Confirma la eliminación de una persona
    const handleConfirmar = () => {
        handleRemoveUser(persona)
    }

    // Importa datos desde un archivo CSV
    const importarDatos = (rows) => {
        personas.forEach((item, index) => {
            const personaRows = rows[index];
            Object.entries(personaRows).forEach(([key, value]) => {
                if (key == "fechaNacimiento") {
                    value = moment(value).format("YYYY-MM-DD")
                }
                const e = { target: { value } }
                handlerInputChange(e, key, index)
            });
        });
    }

    return (
        <div className='container-fluid'>
            <div className='row'>
                {personas.map((persona, idx) => (
                    <div className={`container-campo-personas col-md-6 px-2`} key={idx} >
                        {personas.length !== 1 && parseInt(user?.roll) === SUPERADMIN && (<button type='button' onClick={() => toogleMostrar(persona)} className='BtnAddImagenTrash'>
                            <FontAwesomeIcon icon={faTrash} size="1x" className='icon-remove' />
                        </button>)}
                        <div className={`CampoPersonas ${errors.personas && 'is-invalid'} `}>

                            <h4>Persona {idx + 1}<span style={{ fontSize: 14 }}> {persona.nacionalidad}</span></h4>
                            <MDBInput
                                label="Número de Documento"
                                name="numeroDocumento"
                                className={error.numeroDocumento ? "form-control is-invalid" : null}
                                outline
                                value={persona.numeroDocumento}
                                required={verificarEstado()}
                                onChange={e => handlerInputChange(e, 'numeroDocumento', idx)}
                                disabled={bloquearDatosReserva()}
                            />
                            <MDBInput
                                label="Primer Apellido" name="primerApellido" className={error.primerApellido ? "form-control is-invalid" : null} outline
                                value={persona.primerApellido}
                                required={verificarEstado()}
                                onChange={e => handlerInputChange(e, 'primerApellido', idx)}
                                disabled={bloquearDatosReserva()}
                            />
                            <MDBInput
                                label="Segundo Apellido" name="segundoApellido" className={error.segundoApellido ? "form-control is-invalid" : null} outline
                                value={persona.segundoApellido}
                                onChange={e => handlerInputChange(e, 'segundoApellido', idx)}
                                required={verificarEstado()}
                                disabled={bloquearDatosReserva()}
                            />
                            <MDBInput
                                required={verificarEstado()}
                                value={persona.nombre}
                                style={{ marginTop: 0, marginBottom: 0 }}
                                label="Primer Nombre" name="nombre"
                                outline
                                disabled={bloquearDatosReserva()}
                                onChange={e => handlerInputChange(e, 'nombre', idx)}

                            />
                            <MDBInput
                                value={persona.segundoNombre}
                                style={{ marginTop: 0, marginBottom: 0 }}
                                label="Segundo Nombre" name="segundoNombre"
                                outline
                                disabled={bloquearDatosReserva()}
                                onChange={e => handlerInputChange(e, 'segundoNombre', idx)}

                            />
                            <MDBInput
                                value={persona.sexo}
                                style={{ marginTop: 0, marginBottom: 0 }}
                                label="Sexo M/F" name="Sexo"
                                outline
                                disabled={bloquearDatosReserva()}
                                onChange={e => handlerInputChange(e, 'sexo', idx)}

                            />
                            {(user && parseInt(user.roll) === VISITANTE || !user || persona.correo || persona.telefono) ?
                                <>
                                    <MDBInput
                                        required
                                        value={persona.correo}
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        label="Correo" name="correo"
                                        outline
                                        type='email'
                                        disabled={bloquearDatosReserva()}
                                        onChange={e => handlerInputChange(e, 'correo', idx)}
                                    />
                                    <MDBInput
                                        required
                                        value={persona.telefono}
                                        style={{ marginTop: 0, marginBottom: 0 }}
                                        label="Telefono" name="telefono"
                                        outline
                                        disabled={bloquearDatosReserva()}
                                        onChange={e => handlerInputChange(e, 'telefono', idx)}
                                    />

                                </>
                                : null}

                            <div className="form-group mb-2 mt-2">
                                <input
                                    onChange={e => handlerInputChange(e, 'fechaNacimiento', idx)}
                                    id="fechaNacimiento"
                                    className={`form-control ${error.fechaNacimiento ? "is-invalid" : null} `}
                                    name="fechaNacimiento" value={persona.fechaNacimiento}
                                    placeholder='Fecha Nacimiento'
                                    disabled={bloquearDatosReserva()}
                                    max={new Date().toISOString().split("T")[0]}
                                    min={maxFechaNacimiento}
                                    required={verificarEstado()}
                                    onFocus={(e) => (e.target.type = "date")}
                                    onBlur={(e) => (e.target.type = "text")}

                                />
                            </div>

                            <select className={error.tipoDocumento ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"}
                                name="tipoDocumento"
                                id='tipoDocumento'
                                value={persona.tipoDocumento}
                                onChange={e => handlerInputChange(e, 'tipoDocumento', idx)}
                                required={verificarEstado()}
                                disabled={bloquearDatosReserva()}
                            >
                                <option value="">Selecciona Tipo de Documento</option>
                                <option value={"Cédula"}>Cédula</option>
                                <option value={"Tarjeta de Identidad"}>Tarjeta de Identidad</option>
                                <option value={"Pasaporte"}>Pasaporte</option>
                                <option value={"Cédula de Extranjería"}>Cédula de Extranjería</option>
                            </select>

                            {user && parseInt(user.roll) === TAQUILLA ? <div className="input-group mt-2">
                                <div className="custom-file mr-2">
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        className="custom-file-input"
                                        id="inputGroupFile04"
                                        disabled={persona?.codigoSeguro}
                                        aria-describedby="inputGroupFileAddon04"
                                        onChange={(e) => handleFileChange(e, 'imagen', idx)}
                                    />
                                    <label className="custom-file-label" htmlFor="inputGroupFile04">
                                        {persona?.imagen ? (persona?.imagen.name.length > 15 ? `${persona?.imagen.name.substring(0, 15)}...` : persona?.imagen.name) : persona?.codigoSeguro ? (persona?.codigoSeguro.length > 15 ? `${persona?.codigoSeguro.substring(0, 15)}...` : persona?.codigoSeguro) : 'Adjuntar Seguro'}
                                    </label>
                                </div>

                                <div className="input-group-append">
                                    <button
                                        className=" rounded-pill btn btn-md m-0 px-3 py-2 z-depth-0 waves-effect btn-primary"
                                        type="button"
                                        id="inputGroupFileAddon04"
                                        onClick={() => persona?.codigoSeguro ? handleRemove('codigoSeguro', idx) : handleUpload(persona?.imagen, 'codigoSeguro', idx)}
                                    >
                                        {persona?.codigoSeguro ? 'REMOVER' : 'SUBIR'}
                                    </button>
                                </div>
                            </div>
                                :
                                user && parseInt(user.roll) === VISITANTE
                                    ?
                                    null
                                    : !user ? null :
                                        <div className="input-group mt-2">
                                            <div className="custom-file mr-2">
                                                <input
                                                    type="file"
                                                    accept=".pdf"
                                                    className="custom-file-input"
                                                    id="inputGroupFile04"
                                                    disabled={persona?.codigoSeguro}
                                                    aria-describedby="inputGroupFileAddon04"
                                                    onChange={(e) => handleFileChange(e, 'imagen', idx)}
                                                />
                                                <label className="custom-file-label" htmlFor="inputGroupFile04">
                                                    {persona?.imagen ? (persona?.imagen?.name?.length > 15 ? `${persona?.imagen.name.substring(0, 15)}...` : persona?.imagen.name) : persona?.codigoSeguro ? (persona?.codigoSeguro?.length > 15 ? `${persona?.codigoSeguro.substring(0, 15)}...` : persona?.codigoSeguro) : 'Adjuntar Seguro'}
                                                </label>
                                            </div>

                                            <div className="input-group-append">
                                                <button
                                                    className=" rounded-pill btn btn-md m-0 px-3 py-2 z-depth-0 waves-effect btn-primary"
                                                    type="button"
                                                    id="inputGroupFileAddon04"
                                                    onClick={() => persona?.codigoSeguro ? handleRemove('codigoSeguro', idx) : handleUpload(persona?.imagen, 'codigoSeguro', idx)}
                                                >
                                                    {persona?.codigoSeguro ? 'REMOVER' : 'SUBIR'}
                                                </button>
                                            </div>
                                        </div>
                            }

                        </div>
                    </div>
                ))}
            </div>
            <AlertaConfirmacion mostrar={mostrar} setMostrar={setMostrar} onAceptar={handleConfirmar} />
            {(!serviciosTotal.id && (parseInt(user?.roll) == SUPERADMIN || parseInt(user?.roll) == OPERADOR || parseInt(user?.roll) == TAQUILLA)) && <div className='TablaResumen row'>
                <div className='HeaderTablaResumen'>
                    <div className='row'>
                        <h4>Subir Usuarios</h4>
                    </div>
                </div>
                <div className='CuerpoTablaResumen'>
                    <div className='row '>
                        <div className='col-12 mb-2'>
                            Aquí puedes encontrar un archivo de ejemplo CSV para subir los usuarios:
                            <br />
                            <a href="/campospersonas.csv" download="campospersonas.csv">
                                Descargar archivo CSV
                            </a>
                        </div>
                        <div className='col-12'>
                            <SubirPersonasCSV importarDatos={importarDatos} />
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default CampoPersonas