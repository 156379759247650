import React from 'react'
import Tabla from '../componentes/Tabla';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const VerServicios = ({ data, seleccionaRegistro, baseUrl, tiposIngresos }) => {

	const LeeArray = (array) => {

		let arrayFormat = JSON.parse(array)

		let cadena = ''
		arrayFormat.map(item => {
			tiposIngresos.map(itemm => {
				if (item === itemm.id) {
					cadena = cadena + itemm.nombre + ', '
				}
			})
		})
		return cadena
	}

	const columns = [
		{
			title: '',
			render: (item) => <div className={String(item.activo) == "true" ? "bola_estado_activo" : "bola_estado_desact"}></div>,
			field: "", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: 'Img',
			render: (item) => <div className="ImgProducto"><img src={`${baseUrl}${item.imagen}`} /></div>,
			field: "imagen"
		},
		{
			title: "Nombre", field: "nombre"
		},
		{
			title: "RTN", field: "runt"
		},
		{
			title: "Teléfono", field: "telefono"
		},
		{
			title: "Ingreso", render: (item) => LeeArray(item.ids_tipoingresos)
		},

	];
	const actions = [
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEdit} size="lg" /></div>,
			tooltip: "Editar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'editar'),

		}),
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faTrashAlt} size="lg" /></div>,
			tooltip: "Eliminar",
			onClick: (event, rowData) => seleccionaRegistro(item, 'eliminar'),
		}),]

	return (
		<Tabla columns={columns} data={data} actions={actions} title='Listado de Guias' />
	)
}

export default VerServicios