import React from 'react'
import { NumericFormat } from 'react-number-format'
import Select from 'react-select'
import moment from 'moment'
import { SUPERADMIN } from '../../constantes/roles'

const MostrarInformes = ({ startDate, setStartDate, finalFecha, setFinalFecha, setOperador, user, operadores, informes, imprimirServicios }) => {
    return (
        <div>
            <div className='d-flex flex-wrap'>
                <div className='informes-fechas mb-2'>
                    <label htmlFor="start">INI:</label>
                    <input
                        type="date"
                        className='informes-fechas-input'
                        value={moment(startDate).format("YYYY-MM-DD")}
                        onChange={e => setStartDate(e.target.value)}

                    />
                </div>
                <div className='informes-fechas mb-2'>
                    <label htmlFor="start">FIN:</label>
                    <input
                        className='informes-fechas-input'
                        type="date"
                        value={moment(finalFecha).format("YYYY-MM-DD")}
                        onChange={e => setFinalFecha(e.target.value)}
                    />
                </div>
                {parseInt(user.roll) === SUPERADMIN && <div className='d-flex select-container '>
                    <Select
                        clearValue={() => 0}
                        isClearable
                        name="color"
                        placeholder="OPERADOR"
                        options={operadores && operadores.map(item => ({ label: item.nombre, value: item.id }))}
                        className="react-select-container"
                        classNamePrefix="react-select-informes"
                        onChange={(e) => e ? setOperador(parseInt(e.value)) : setOperador(null)}
                    />
                </div>}
            </div>

            {informes && informes.map((item, index) =>
                <div className='informes-zonas' key={item.nombre}>
                    <div className='informes-zonas_linea'>
                    </div>
                    <div className='informes-zonas_container'>
                        <p className='mb-2'>{item.nombre}</p>

                        <div className='informes-zonas_container_item'>

                            <div className='informes-zonas_container_item_informacion'>
                                <div className='informes-zonas_container_item_informacion_cantidad'>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'>({item.totalReservas})</p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'><NumericFormat displayType="text" value={item.totalReservasDinero} prefix={'$'} thousandSeparator={true} /></p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_total'>Total Reservas</p>
                                </div>
                                <div className='informes-zonas_container_item_informacion_linea AzulClaro'>
                                </div>
                            </div>

                            <div className='informes-zonas_container_item_informacion'>
                                <div className={`informes-zonas_container_item_informacion_cantidad ${item.alertas}`}>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'>{item.limiteOcupacion} de {item.limite}</p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_total'>Ocupacion</p>
                                </div>
                                <div className='informes-zonas_container_item_informacion_linea Rojo'>
                                </div>
                            </div>

                            <div className='informes-zonas_container_item_informacion cursor' onClick={() => imprimirServicios(item.id, 'Vehiculo', 'Servicio Vehiculo')}>
                                <div className='informes-zonas_container_item_informacion_cantidad'>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'>({item.totalServicioVehiculo})</p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'><NumericFormat displayType="text" value={item.totalServicioVehiculoDinero} prefix={'$'} thousandSeparator={true} /></p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_total'>Servicio de Transporte</p>
                                </div>
                                <div className='informes-zonas_container_item_informacion_linea Amarillo'>
                                </div>
                            </div>

                            <div className='informes-zonas_container_item_informacion cursor' onClick={() => imprimirServicios(item.id, 'Alimentacion', 'Servicio Alimentación')}>
                                <div className='informes-zonas_container_item_informacion_cantidad'>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'>({item.totalServicioAlimentacion})</p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'><NumericFormat displayType="text" value={item.totalServicioAlimentacionDinero} prefix={'$'} thousandSeparator={true} /></p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_total'>Servicio de Alimentacion</p>
                                </div>
                                <div className='informes-zonas_container_item_informacion_linea VerdeClaro'>

                                </div>
                            </div>

                            <div className='informes-zonas_container_item_informacion cursor' onClick={() => imprimirServicios(item.id, 'Guianza', 'Servicio Guianza')}>
                                <div className='informes-zonas_container_item_informacion_cantidad'>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'>({item.totalServicioGuianza})</p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'><NumericFormat displayType="text" value={item.totalServicioGuianzaDinero} prefix={'$'} thousandSeparator={true} /></p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_total'>Servicio de Guianza</p>
                                </div>
                                <div className='informes-zonas_container_item_informacion_linea Naranja'>
                                </div>
                            </div>

                            <div className='informes-zonas_container_item_informacion cursor' onClick={() => imprimirServicios(item.id, 'Hospedaje', 'Servicio Hospedaje')}>
                                <div className='informes-zonas_container_item_informacion_cantidad'>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'>({item.totalServicioHospedaje})</p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_parrafo'><NumericFormat displayType="text" value={item.totalServicioHospedajeDinero} prefix={'$'} thousandSeparator={true} /></p>
                                    <p className='informes-zonas_container_item_informacion_cantidad_total'>Servicio de Hospedaje</p>
                                </div>
                                <div className='informes-zonas_container_item_informacion_linea Morado'>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default MostrarInformes