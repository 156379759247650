import React, { useContext, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import BotonAdmin from '../componentes/BotonAdmin'
import * as RUTAS from '../../constantes/rutas';
import UserContext from '../../context/UserContext';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import { ApiPaths } from '../../utils';
import axios from 'axios';
import { Image } from 'react-bootstrap';
import ImagenLogo from '../../paginas/img/logoTecnosoluciones.png';

const Navegacion = () => {
    const { salirSeccion } = useContext(UserContext)
    const { user, cambiarInformacionTickets, ticketsPendientes } = useContext(UserContext);

    useEffect(() => {
        const peticionDatos = async () => {
            try {
                // Verifica si el usuario es SUPERADMIN y obtiene los tickets
                if (user && parseInt(user.roll) === SUPERADMIN) {
                    const resp = await axios.get(ApiPaths.tickets)
                    let ticketsSinLeer = 0;
                    resp && resp.data.map(ticket => {
                        if (ticket.visto == 'false') {
                            ticketsSinLeer += 1
                        }
                    })
                    return cambiarInformacionTickets(ticketsSinLeer)
                }

                // Verifica si el usuario es TAQUILLA u OPERADOR y obtiene los tickets
                if (user && (parseInt(user.roll) === TAQUILLA || parseInt(user.roll) === OPERADOR)) {
                    const resp = await axios.get(ApiPaths.tickets, { params: { idOperador: user.id_operador } })
                    let ticketsSinLeer = 0;
                    resp && resp.data.map(ticket => {
                        if (ticket.visto == 'false') {
                            ticketsSinLeer += 1
                        }
                    })
                    return cambiarInformacionTickets(ticketsSinLeer)
                }

                // Verifica si el usuario es VISITANTE y obtiene los tickets
                if (user && parseInt(user.roll) === VISITANTE) {
                    const resp = await axios.get(ApiPaths.tickets, { params: { idUsuario: user.id } })
                    let ticketsSinLeer = 0;
                    resp && resp.data.map(ticket => {
                        if (ticket.visto == 'false') {
                            ticketsSinLeer += 1
                        }
                    })
                    return cambiarInformacionTickets(ticketsSinLeer)
                }

            } catch (error) {
                console.log(error)
            }
        }

        peticionDatos()
    }, [])

    return (
        <>
            {/* Enlaces de navegación para diferentes rutas */}
            <NavLink to={RUTAS.ADMIN_RESERVA}>
                {({ isActive, isPending }) => (
                    <BotonAdmin nombre='Reservas' active={isActive} />
                )}
            </NavLink>

            {/* Enlaces adicionales para SUPERADMIN */}
            {user.roll && parseInt(user.roll) === SUPERADMIN &&
                <>
                    <NavLink to={RUTAS.ADMIN_OPERADORES}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Operadores' active={isActive} />
                        )}
                    </NavLink>

                    <NavLink to={RUTAS.ADMIN_TIPOS_INGRESO}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Tipos de Ingresos' active={isActive} />
                        )}
                    </NavLink>
                    <NavLink to={RUTAS.ADMIN_SERVICIOS}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Servicios' active={isActive} />
                        )}
                    </NavLink>
                    <NavLink to={RUTAS.ADMIN_GUIAS}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Guias' active={isActive} />
                        )}
                    </NavLink>
                </>
            }

            <NavLink to={RUTAS.ADMIN_USUARIOS}>
                {({ isActive, isPending }) => (
                    <BotonAdmin nombre='Usuarios' active={isActive} />
                )}
            </NavLink>
            <NavLink to={RUTAS.ADMIN_RESERVAS_DIA}>
                {({ isActive, isPending }) => (
                    <BotonAdmin nombre='Reservas del Día' active={isActive} />
                )}
            </NavLink>
            <NavLink to={RUTAS.ADMIN_TICKETS}>
                {({ isActive, isPending }) => (
                    <div className='tickets-notificacion-navegacion'>
                        <BotonAdmin nombre='Tickets' active={isActive} />
                        {ticketsPendientes > 0 && <div className='tickets-notificacion-navegacion-icon'><p>{ticketsPendientes}</p></div>}
                    </div>

                )}
            </NavLink>



            {user.roll && parseInt(user.roll) === SUPERADMIN &&
                <>
                    <NavLink to={RUTAS.ADMIN_TRANSACCIONES}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Transacciones' active={isActive} />
                        )}
                    </NavLink>
                    <NavLink to={RUTAS.ADMIN_TARIFAS}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Tarifas' active={isActive} />
                        )}
                    </NavLink>
                </>
            }
            {user.roll && parseInt(user.roll) <= TAQUILLA &&
                <> <NavLink to={RUTAS.ADMIN_VISITANTES}>
                    {({ isActive, isPending }) => (
                        <BotonAdmin nombre='Visitantes' active={isActive} />
                    )}
                </NavLink>
                    <NavLink to={RUTAS.ADMIN_INFORMES}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Informes' active={isActive} />
                        )}
                    </NavLink>

                </>
            }


            {user.roll && parseInt(user.roll) === OPERADOR &&
                <>
                    <a href={RUTAS.TUTORIALES_OPERADOR} target="_blank" rel="noopener noreferrer">
                        <BotonAdmin nombre='Tutoriales' />
                    </a>
                </>
            }

            {user.roll && parseInt(user.roll) === SUPERADMIN &&
                <>
                    <NavLink to={RUTAS.HISTORIALRESERVAS}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Historial Reservas' active={isActive} />
                        )}
                    </NavLink>
                    <NavLink to={RUTAS.ADMIN_CONFIGURACION}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Configuración' active={isActive} />
                        )}
                    </NavLink>

                    <NavLink to={RUTAS.ADMIN_METODO_PAGO}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Metodos de Pago' active={isActive} />
                        )}
                    </NavLink>

                    <NavLink to={RUTAS.ADMIN_BACKUPS}>
                        {({ isActive, isPending }) => (
                            <BotonAdmin nombre='Respaldo' active={isActive} />
                        )}
                    </NavLink>

                    <a href={RUTAS.TUTORIALES_ADMIN} target="_blank" rel="noopener noreferrer">
                        <BotonAdmin nombre='Tutoriales Admin' />
                    </a>

                </>

            }

            {/* Enlace para cerrar sesión */}
            <NavLink to='/' onClick={salirSeccion}>
                {({ isActive, isPending }) => (
                    <BotonAdmin nombre='Cerrar Sesión' active={isActive} />
                )}
            </NavLink>
            <div className='login-links-alternos-navegacion'>
                <p>Desarrollado por:</p>
                <Image
                    src={ImagenLogo}
                />

            </div>
        </>

    )
}

export default Navegacion