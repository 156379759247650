import React from "react";
import { Image } from "react-bootstrap";
import { ApiPaths } from "../../../utils";

const InputAutocomplete = ({ onChangeBuscarInput, dataEncontrada, itemSeleccionado, inputValue, cargandoBusqueda }) => (
  <div className="input_autocomplete">
    <input
      type="text"
      required
      className="form-control mt-2 input_autocomplete_input"
      onChange={(e) => onChangeBuscarInput(e.target.value)}
      onKeyDown={(e) => {
        if (e.keyCode === 9) e.preventDefault();
        if (e.keyCode === 13) e.preventDefault();
      }}
      value={inputValue}
    />
    <div className="input_autocomplete_data-encontrada">
      {cargandoBusqueda && <div className="spinner">
        <div class="fa-2x ">
          <i class="fas fa-spinner fa-pulse"></i>
        </div>
      </div>}
      {
        dataEncontrada && dataEncontrada.map(data => (
          <div key={data.id} className="input_autocomplete_data-item" onClick={() => itemSeleccionado(data)}>
            <div className="input_autocomplete_data-item-image">
              <Image src={ApiPaths.operadores + data.operadorImagen} />
            </div>
            <div className="input_autocomplete_data-item-reserva">
              <div>
                <span>Estado: <b className={`estados ${data.estado}`}>{data.estado}</b></span>
                <span className='estado-reservas'> <b className={`estados ${data.estadoReserva.background}`}>{data.estadoReserva.estado}</b></span>
              </div>
              <h2 className="mb-0">COD: <b>{data.codigoReserva}</b></h2>
              <span>OPERADORA: <b>{data.operador}</b></span>
              <span>Fecha de realización de la Reserva: <b>{data.fechaReserva}</b></span>
            </div>
          </div>
        ))
      }

    </div>

  </div>
)




export default InputAutocomplete;