import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import UserContext from '../../context/UserContext'
import { SUPERADMIN } from '../../constantes/roles'

const PeticionTraeDatos = (Url, parametros) => {
    const [data, setData] = useState([])
    const { user } = useContext(UserContext);
    const peticionTraeDatosAll = async () => {
        try {
            if (parametros) {
                const resp = await axios.get(Url, { params: parametros })
                return setData(resp.data);
            }
            if (user && parseInt(user.roll) != SUPERADMIN) {
                const resp = await axios.get(Url, { params: { idUsuario: user.id_operador } })
                return setData(resp.data)
            }
            const resp = await axios.get(Url, { params: parametros })

            setData(resp.data)
        } catch (error) {
            setData([])
            return error
        }
    }

    useEffect(() => {
        peticionTraeDatosAll()
    }, [Url])
    return [data, setData]
}

export default PeticionTraeDatos