import React, { useState, useEffect } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from 'mdbreact';
import axios from 'axios'
import { ApiPaths } from '../../utils';
import { NumericFormat } from 'react-number-format';

const EditarAbonos = ({ baseUrl, setFuncion, registroSelect, setRegistroSelect }) => {
	const [error, setError] = useState({ activo: false, mensaje: 'Hola' })
	const [mediosPago, setmediosPago] = useState([])

	useEffect(() => {
		const peticionTraeDatos = async () => {
			try {
				const response = await axios.get(ApiPaths.metodosPago)
				setmediosPago(response?.data.map(item => ({ ...item, label: item.nombre, value: item.nombre })))

			} catch (error) {
				console.log(error)
			}

		}
		peticionTraeDatos()
	}, [])

	const peticionPut = async () => {
		let { id, valor, medio_pago, estado } = registroSelect
		let f = new FormData()
		f.append('id', id)
		f.append('valor', valor)
		f.append('medio_pago', medio_pago)
		f.append('estado', estado)
		f.append('METHOD', 'PUT')

		await axios.post(baseUrl, f)
			.then(response => {

			}).catch(error => {
				console.log('muestra erro: ' + error);
			})
		setFuncion('ver')
	}

	return (
		<div>
			<MDBContainer>
				<MDBRow>
					<MDBCol>
						<hr />
						{error.activo ?
							<span className="error"> Error: {error.mensaje}</span> : null
						}

						<div className="row sinMargen">
							<div className="col-md-6">
								<NumericFormat
									required
									prefix="$"
									thousandSeparator
									className='form-control mb-4'
									placeholder='Valor'
									value={registroSelect.valor}
									onValueChange={(values) => setRegistroSelect({ ...registroSelect, valor: values.value })} />
							</div>

							<div className="col-md-6">
								<select
									className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll" required
									value={registroSelect.medio_pago}
									onChange={e => setRegistroSelect({ ...registroSelect, medio_pago: e.target.value })}>
									<option value="">Selecciona Medio de Pago</option>
									<option value="SALDO">SALDO</option>
									{mediosPago.map(item => (
										<option key={item.id} value={item.value}>{item.value}</option>
									))}
								</select>

							</div>
							<div className="col-md-6">
								<select
									className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll" required
									value={registroSelect.estado}
									onChange={e => setRegistroSelect({ ...registroSelect, estado: e.target.value })}>
									<option value={"activo"}>activo</option>
									<option value={"inactivo"}>inactivo</option>
								</select>

							</div>

						</div>


						<div className="text-center mt-4">
							<div className="col text-center">
								<button
									onClick={peticionPut}
									className="btn boton_estandar"
								>Editar Abonos
								</button>
							</div>
						</div>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	)
}

export default EditarAbonos