import React, { useContext, useState } from "react";
import { Navigate } from 'react-router-dom';
import Navegacion from "../navigacion/Navegacion";
import Logo from '../../paginas/img/logo.png'
import Avatar from '../../paginas/img/avatar.jpg'
import HeaderUserPrin from '../componentes/HeaderUserPrin'
//import '@fortawesome/fontawesome-free/css/all.min.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
//import 'mdbreact/dist/css/mdb.css';

import UserContext from "../../context/UserContext";

const UseValidarRutas = ({ children, scopes = [], ...props }) => {
    const { user } = useContext(UserContext)

    if (user) {
        return (
            <div className=" principal">
                <div className="col-xs-12 col-sm-12 col-md-3">
                    <div className="barra_izquierda">

                        <HeaderUserPrin
                            Logo={Logo}
                            Avatar={Avatar}
                            nombre={user.nombre}
                            idUsuario={user.id}
                            Roll={user.roll}
                            operador={user.operador}
                            imagenOperador={user.operadorImagen}
                        />
                        <Navegacion />

                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-9">
                    <div className="barra_derecha">

                        <div className="contenedorAdmin">

                            <div className="contenedor">

                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return <Navigate to='/' />

}

export default UseValidarRutas;