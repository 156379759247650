import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from "./roles";

export const verificarRoles = (rol) => rol === SUPERADMIN ? "SUPERADMIN" : rol === OPERADOR ? "OPERADOR" : rol === TAQUILLA ? "TAQUILLA" : rol === VISITANTE ? "VISITANTE" : null;

export const idiomaCSV = {
    general: {
        goToPreviousStepTooltip: 'Ir al paso anterior'
    },
    fileStep: {
        initialDragDropPrompt: 'Arrastre y suelte el archivo CSV aquí, o haga clic para seleccionar en la carpeta',
        activeDragDropPrompt: 'Suelte el archivo CSV aquí...',
        getImportError: (message) => `Error de importación: ${message}`,
        getDataFormatError: (message) => `Por favor, compruebe el formato de los datos: ${message}`,
        goBackButton: 'Volver atrás',
        nextButton: 'Elegir columnas',
        rawFileContentsHeading: 'Contenido de archivos raw',
        previewImportHeading: 'Vista previa de importación',
        dataHasHeadersCheckbox: 'Los datos tienen encabezados',
        previewLoadingStatus: 'Cargando vista previa...'
    },
    fieldsStep: {
        stepSubtitle: 'Seleccionar columnas',
        requiredFieldsError: 'Por favor, asignar todos los campos necesarios',
        nextButton: 'Importar',
        dragSourceAreaCaption: 'Columnas a importar',
        getDragSourcePageIndicator: (currentPage, pageCount) => `Pagina ${currentPage} de ${pageCount}`,
        getDragSourceActiveStatus: (columnCode) => `Asignar columna ${columnCode}`,
        nextColumnsTooltip: 'Mostrar las columnas siguientes',
        previousColumnsTooltip: 'Mostrar columnas anteriores',
        clearAssignmentTooltip: 'Borrar asignación de columna',
        selectColumnTooltip: 'Seleccionar columna para asignación',
        unselectColumnTooltip: 'Deseleccionar columna',
        dragTargetAreaCaption: 'Campos de destino',
        getDragTargetOptionalCaption: (campo) => `${campo} (opcional)`,
        getDragTargetRequiredCaption: (field) => `${field} (required)`,
        dragTargetPlaceholder: 'Arrastrar columna aquí',
        getDragTargetAssignTooltip: (columnCode) => `Asignar columna ${columnCode}`,
        dragTargetClearTooltip: 'Borrar la asignación de columnas',
        columnCardDummyHeader: 'Campo no asignado',
        getColumnCardHeader: (code) => `Columna ${code}`
    },
    progressStep: {
        stepSubtitle: 'Importación',
        uploadMoreButton: 'Subir más',
        finishButton: 'Terminar',
        statusError: 'No se puede importar',
        estatuaCompleta: 'Completa',
        statusPending: 'Importando...',
        processedRowsLabel: 'Filas procesadas:'
    }
}