import React from 'react'
import 'react-toastify/dist/ReactToastify.css';

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from './paginas/Login.js';
import Error404 from './paginas/Error404';
import UseValidarRutas from './componentesAdmin/hooks/UseValidarRutas.js';
import EscritorioAdmin from './componentesAdmin/escritorio/EscritorioAdmin.js';
import OperadoresAdmin from './componentesAdmin/operadores/OperadoresAdmin.js';
import TiposIngresos from './componentesAdmin/tiposIngresos/TiposIngresos.js';
import ServiciosAdmin from './componentesAdmin/servicios/ServiciosAdmin.js';
import GuiasAdmin from './componentesAdmin/guias/GuiasAdmin.js';
import UsuariosAdmin from './componentesAdmin/usuarios/VerUsuarios.js';
import UsuariosCrearAdmin from './componentesAdmin/usuarios/SubirUsuario.js';
import UsuariosEditarAdmin from './componentesAdmin/usuarios/EditarUsuario.js';
import TransaccionesAdmin from './componentesAdmin/transacciones/TransaccionesAdmin.js';
import TarifasAdmin from './componentesAdmin/tarifas/TarifasAdmin.js';
import VisitantesAdmin from './componentesAdmin/visitantes/VisitantesAdmin.js';
import ConfigAdmin from './componentesAdmin/configuracion/ConfigAdmin.js';
import ReservasAdmin from './componentesAdmin/reservas/ReservasAdmin.js';
import VerInformes from './componentesAdmin/informes/VerInformes.js';
import { ToastContainer } from 'react-toastify';
import * as RUTAS from './constantes/rutas';
import DetalleReserva from './componentesAdmin/reservas/DetalleReserva';
import { UserProvider } from './context/UserContext.js';
import Operadores from './paginas/Operadores.js';
import Guias from './paginas/Guias.js';

import MetodosPagoAdmin from './componentesAdmin/metodosPago/VerMetodosPago.js';
import MetodosPagoCrearAdmin from './componentesAdmin/metodosPago/SubirMetodosPago.js';
import MetodosPagoEditarAdmin from './componentesAdmin/metodosPago/EditarMetodosPago.js';
import VerTickets from './componentesAdmin/tickets/VerTickets.js';
import SubirTickets from './componentesAdmin/tickets/SubirTickets.js';
import EditarTickets from './componentesAdmin/tickets/EditarTickets.js';
import VerBackups from './componentesAdmin/backups/VerBackups.js';
import CrearReserva from './componentesAdmin/reservas/reservaVisitante/CrearReserva.js';
import RecuperarContrasena from './paginas/RecuperarContrasena.js';
import VerReservasDia from './componentesAdmin/informes/VerReservasDia.js';
import TransaccionEstado from './componentesAdmin/transaccion/Transaccion_estado.js';
import HistorialReservas from './componentesAdmin/historialreservas/HistorialAdmin.js';
import HistorialDetalleReserva from './componentesAdmin/historialreservas/DetalleReserva';

// Componente principal de la aplicación
const App = () => (
  <React.StrictMode>
    <UserProvider>
      <BrowserRouter>
        <Routes>
          {/* Definición de rutas de la aplicación */}
          <Route path='/' element={<Login />} />
          <Route path={RUTAS.ADMIN_ESCRITORIO} element={<UseValidarRutas><EscritorioAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_OPERADORES} element={<UseValidarRutas><OperadoresAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_TIPOS_INGRESO} element={<UseValidarRutas><TiposIngresos /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_SERVICIOS} element={<UseValidarRutas><ServiciosAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_GUIAS} element={<UseValidarRutas><GuiasAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_USUARIOS} element={<UseValidarRutas><UsuariosAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_USUARIOS_CREAR} element={<UseValidarRutas><UsuariosCrearAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_USUARIOS_EDITAR} element={<UseValidarRutas><UsuariosEditarAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_TRANSACCIONES} element={<UseValidarRutas><TransaccionesAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_TARIFAS} element={<UseValidarRutas><TarifasAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_VISITANTES} element={<UseValidarRutas><VisitantesAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_CONFIGURACION} element={<UseValidarRutas><ConfigAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_RESERVA} element={<UseValidarRutas><ReservasAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_RESERVA_VER} element={<UseValidarRutas><DetalleReserva /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_INFORMES} element={<UseValidarRutas><VerInformes /></UseValidarRutas>} />
          <Route path={RUTAS.OPERADORES} element={<Operadores />} />
          <Route path={RUTAS.GUIAS} element={<Guias />} />
          <Route path={RUTAS.ADMIN_METODO_PAGO} element={<UseValidarRutas><MetodosPagoAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_METODO_PAGO_CREAR} element={<UseValidarRutas><MetodosPagoCrearAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_METODO_PAGO_EDITAR} element={<UseValidarRutas><MetodosPagoEditarAdmin /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_TICKETS} element={<UseValidarRutas><VerTickets /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_TICKETS_CREAR} element={<UseValidarRutas><SubirTickets /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_TICKETS_EDITAR} element={<UseValidarRutas><EditarTickets /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_BACKUPS} element={<UseValidarRutas><VerBackups /></UseValidarRutas>} />
          <Route path={RUTAS.ADMIN_RESERVAS_DIA} element={<UseValidarRutas><VerReservasDia /></UseValidarRutas>} />
          <Route path={RUTAS.RESERVAS} element={<CrearReserva />} />
          <Route path={RUTAS.RECUPERAR_CONTRASENA} element={<RecuperarContrasena />} />
          <Route path={RUTAS.ESTADO_TRANSACCION} element={<TransaccionEstado />} />
          <Route path={RUTAS.ESTADO_TRANSACCION_APROBADO} element={<TransaccionEstado />} />
          <Route path={RUTAS.ESTADO_TRANSACCION_RECHAZADO} element={<TransaccionEstado />} />
          <Route path={RUTAS.HISTORIALRESERVAS} element={<UseValidarRutas><HistorialReservas /></UseValidarRutas>} />
          <Route path={RUTAS.HISTORIALRESERVASDETALLE} element={<UseValidarRutas><HistorialDetalleReserva /></UseValidarRutas>} />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </UserProvider>
  </React.StrictMode>
)

export default App