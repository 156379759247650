// URL base para las solicitudes a la API
const baseUrl = 'https://reservasnevados.com/'
//const baseUrl = 'http://localhost:8888/'

// Ruta base de la API
const api = "apinevados/"

// Tipos de métodos utilizados en la aplicación
const METHODS_TYPES = {
	adicionar: "add",
	remover: "remove"
}

// Tipos de servicios disponibles
const TIPO_SERVICIOS = {
	alimentacion: "Alimentacion",
	vehiculo: "Vehiculo",
	alquiler: "Alquiler",
	guianza: "Guianza",
	hospedaje: "Hospedaje",
	vehiculoCodigo: "VehiculoCodigo"
}

// Tipos de tarifas disponibles
const TIPO_TARIFAS = {
	jovenes: "Jóvenes 6 años a 25 años",
	adultos: "Adultos de 25 años a 65 Años",
	mayores: "Adulto Mayores",
	extranjero: "Ingreso 6 años a 70 años Extranjero"
}

// Claves de la API de Nuvei para el entorno de desarrollo
const KeysNuvei = {
	API_LOGIN_DEV: "DV-UNITEMOPENE-CO-SERVER",
	API_KEY_DEV: "Nbz9tQi8dS1DToCpAaVe7nobFwelWC",
	API_URL_DEV: "https://noccapi.paymentez.com/linktopay/init_order/"
}

// Claves de la API de Nuvei para el entorno de prueba
// const KeysNuvei = {
// 	API_LOGIN_DEV: "DV-UNITEMOPENE-STG-CO-SERVER",
// 	API_KEY_DEV: "psi00UQQpxUw3KSWHNQiGV5XWrXK6N",
// 	API_URL_DEV: "https://noccapi-stg.paymentez.com/linktopay/init_order/"
// }

// Rutas de la API para diferentes recursos
const ApiPaths = {
	operadores: baseUrl + api + 'operadores/',
	operadoresAbonos: baseUrl + api + 'operadoresabonos/',
	usuarios: baseUrl + api + 'usuarios/',
	ingresos: baseUrl + api + 'tiposdeingreso/',
	servicios: baseUrl + api + 'servicios/',
	guias: baseUrl + api + 'guias/',
	transacciones: baseUrl + api + 'transacciones/',
	tarifas: baseUrl + api + 'tarifas/',
	visitantes: baseUrl + api + 'visitantes/',
	reservas: baseUrl + api + 'reservas/',
	informes: baseUrl + api + 'informes/',
	condiciones: baseUrl + api + 'condiciones/',
	metodosPago: baseUrl + api + 'metodosdepago/',
	tickets: baseUrl + api + 'tickets/',
	tokens: baseUrl + api + 'tokens/',
	reservasImages: baseUrl + api + 'reservas/uploads.php',
	historialreservas: baseUrl + api + 'historialreservas/',
}

// Exportar constantes y objetos para su uso en otros módulos
export {
	baseUrl,
	api,
	ApiPaths,
	METHODS_TYPES,
	TIPO_SERVICIOS,
	TIPO_TARIFAS,
	KeysNuvei
}