import React, { useState, useEffect } from 'react'
import VerOperadores from './VerTiposIngreso'
import EditarTipoIngreso from './EditarTipoIngreso'
import SubirTipoIngreso from './SubirTipoIngreso'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import { Titulos, peticionDelete } from '../hooks/Funciones'

import { ApiPaths } from '../../utils';

const TiposIngresos = ({ urlIngresos }) => {

	const [tituloPag, setTituloPag] = useState("Tipos de ingreso")
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [tiposingresos, setTiposingresos] = useTraeDatos(ApiPaths.ingresos)
	const TitulosPagina = ['Ver Tipos de Ingreso', 'Crear Tipo de Ingreso', 'Editar Tipo de Ingreso', 'Eliminar Tipos de Ingreso', 'Tipos de Ingreso']

	const seleccionaRegistro = (dato, funcion) => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen })
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () => {
		peticionDelete(registroSelect, ApiPaths.ingresos, tiposingresos, setTiposingresos, setFuncion)
	}

	useEffect(() => { Titulos(funcion, setTituloPag, TitulosPagina) }, [funcion])
	//console.log(tiposingresos)

	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} />
			{funcion === 'crear' ?
				<SubirTipoIngreso
					baseUrl={ApiPaths.ingresos}
					setFuncion={setFuncion}
					data={tiposingresos}
					setData={setTiposingresos}
					Titulo={'Subir Tipo de Ingreso'}
					setTituloPag={setTituloPag} />

				: null}
			{funcion === 'editar' ?
				<EditarTipoIngreso
					baseUrl={ApiPaths.ingresos}
					setFuncion={setFuncion}
					data={tiposingresos}
					setData={setTiposingresos}
					setTituloPag={setTituloPag}
					registroSelect={registroSelect}
					setRegistroSelect={setRegistroSelect} />
				: null}
			{funcion === 'ver' ?
				<VerOperadores
					data={tiposingresos}
					seleccionaRegistro={seleccionaRegistro}
					setFuncion={setFuncion}
					setRegistroSelect={setRegistroSelect}
				/>
				: null}
			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null}
		</div>
	)
}

export default TiposIngresos