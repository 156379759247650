import React from 'react'
import { Button, Modal } from 'react-bootstrap'

export const AlertaConfirmacion = ({ mostrar, setMostrar, onAceptar = () => { }, onCancelar = () => { }, textoinformativo = '¿Está seguro de realizar esta acción?', children, textotitulo = 'Confirmación de acción' }) => {

  const handleAceptar = () => {
    onAceptar()
    setMostrar(false)
  }

  const handleCancelar = () => {
    onCancelar()
    setMostrar(false)
  }

  return (
    <Modal show={mostrar} onHide={handleCancelar}>
      <Modal.Header closeButton>
        <Modal.Title>{textotitulo}</Modal.Title> </Modal.Header>
      <Modal.Body>
        {children ? children : <h4>{textoinformativo}</h4>}

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCancelar}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleAceptar}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
