import React, { useContext, useEffect, useState } from 'react'
import Avatar from '../../paginas/img/avatar.jpg'
import axios from 'axios'
import { ApiPaths } from '../../utils.js';
import Header from '../componentes/Header';
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import { verificarRoles } from '../../constantes/global';
import UserContext from '../../context/UserContext';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import Eliminar from '../componentes/Eliminar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Tabla from '../componentes/Tabla';

const VerUsuarios = () => {
	const navigate = useNavigate()
	const [data, setData] = useState([])
	const { user } = useContext(UserContext);
	const [funcion, setFuncion] = useState('ver')
	const [registroSelect, setRegistroSelect] = useState()

	useEffect(() => { peticionTraeDatos() }, [])

	const peticionTraeDatos = async () => {
		try {
			if (parseInt(user.roll) == OPERADOR || parseInt(user.roll) == TAQUILLA) {
				const response = await axios.get(ApiPaths.usuarios, { params: { id: '14', usuario: '1', clave: '1', unico: 'false', idOperador: user.id_operador } })
				return setData(response.data)
			}
			if (parseInt(user.roll) == VISITANTE) {
				const response = await axios.get(ApiPaths.usuarios, { params: { id: '14', usuario: '1', clave: '1', unico: 'false', idUsuario: user.id } })
				return setData(response.data)
			}

			const response = await axios.get(ApiPaths.usuarios, { params: { id: '14', usuario: '1', clave: '1', unico: 'false' } })
			setData(response.data)
		} catch (error) {
			console.log(error)
		}

	}

	const Seleccionar = async (objeto, funcion) => {
		try {
			if (funcion === "editar") {
				navigate(`${RUTAS.ADMIN_USUARIOS}/editar/${objeto.id}`, { state: objeto })
			}
			if (funcion === "eliminar") {
				setFuncion(funcion)
				setRegistroSelect(objeto)
			}
		} catch (error) {
			console.log(error)
		}

	}

	const peticionDeletee = async () => {
		try {
			let f = new FormData();
			f.append("METHOD", "DELETE");
			await axios.post(ApiPaths.usuarios, f, { params: { id: registroSelect.id } })
			peticionTraeDatos()
			setFuncion('ver')
		} catch (error) {
			console.log(error)
		}
	}

	const columns = [
		{
			title: '',
			render: (item) => <div className={String(item.activo) == "true" ? "bola_estado_activo" : "bola_estado_desact"}></div>,
			field: "", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: 'Img',
			render: (item) => <div className="img_user"><img src={Avatar} /></div>,
			field: "Img"
		},
		{
			title: "Activo", render: (item) => item.activo === 'true' ? 'Si' : 'No', field: "activo"
		},
		{
			title: "Usuario", field: "usuario"
		},
		{
			title: "Operador", field: "operador"
		},
		{
			title: "Roll", render: (item) => verificarRoles(parseInt(item.roll))
		},

	];
	const actions = [
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEdit} size="lg" /></div>,
			tooltip: "Editar",
			onClick: (event, rowData) => Seleccionar(item, 'editar'),

		}),
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faTrashAlt} size="lg" /></div>,
			tooltip: "Eliminar",
			onClick: (event, rowData) => Seleccionar(item, 'eliminar'),
		}),]

	return (
		<div>
			<Header titulo='Ver Usuarios' funcion={funcion} setFuncion={() => navigate(RUTAS.ADMIN_USUARIOS_CREAR)} Pagina={'Usuarios'} Agregar={true} />

			{funcion === 'ver' ?
				<div className="row">
					<div className="col-md-12">
						<Tabla columns={columns} data={data} actions={actions} title='Listado de Usuarios' />
					</div>
				</div>
				: null
			}

			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Usuario'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null
			}


		</div>
	)
}

export default VerUsuarios