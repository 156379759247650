import axios from "axios";
import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { ApiPaths } from "../utils";
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from "../constantes/roles";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [user, setUser] = useState();
    const [tiposDeIngreso, setTiposDeIngreso] = useState();
    const [ticketsPendientes, setTicketsPendientes] = useState(0);

    useEffect(() => {
        if (localStorage.getItem('user')) {
            setUser(JSON.parse(localStorage.getItem('user')));
        }
        cambiarInformacionTiposIngreso();
    }, []);

    const cambiarInformacionUser = useCallback((data) => {
        setUser(data);
        localStorage.setItem('user', JSON.stringify(data));
    }, []);

    const salirSeccion = useCallback(() => {
        setUser();
        localStorage.removeItem('user');
    }, []);

    const cambiarInformacionTiposIngreso = useCallback(async () => {
        try {
            const resp = await axios.get(ApiPaths.ingresos);
            setTiposDeIngreso(resp.data);
        } catch (error) {
            setTiposDeIngreso(null);
        }
    }, []);

    const cambiarInformacionTickets = useCallback((data) => {
        setTicketsPendientes(data);
    }, []);

    const contextValue = useMemo(() => ({
        user,
        cambiarInformacionUser,
        salirSeccion,
        tiposDeIngreso,
        cambiarInformacionTiposIngreso,
        ticketsPendientes,
        cambiarInformacionTickets
    }), [
        user,
        cambiarInformacionUser,
        salirSeccion,
        tiposDeIngreso,
        cambiarInformacionTiposIngreso,
        ticketsPendientes,
        cambiarInformacionTickets
    ]);

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContext;
