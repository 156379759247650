import React from 'react'
import SubirOperador from '../componentesAdmin/operadores/SubirOperador';
import { ApiPaths } from '../utils';

const Operadores = () => {
    return (
        <div className="container d-flex justify-content-center mt-5">
            <div className="card mb-4  w-75">
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-10">
                            <div className='d-flex align-items-center '>
                                <h2 className='font-weight-light'>Crear Operador</h2>
                            </div>
                        </div>
                    </div>
                    <SubirOperador baseUrl={ApiPaths.operadores} />
                </div>
            </div>
        </div>
    )
}

export default Operadores