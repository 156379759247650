import React, { useState, useEffect, useContext } from 'react'
import FormularioLogin from '../componentesAdmin/login/formulario_login.js'
import axios from 'axios'
import md5 from 'md5'
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../constantes/rutas';
import UserContext from '../context/UserContext.js'
import { VISITANTE } from '../constantes/roles.js';
import { ApiPaths } from '../utils.js';

const Login = () => {
	const { user } = useContext(UserContext)
	const { cambiarInformacionUser } = useContext(UserContext)

	const sede = "Parque Nevados"

	const baseUrlUsers = ApiPaths.usuarios;
	const [form, setForm] = useState({ usuario: '', clave: '' })
	const [errorForm, setErrorForm] = useState('')

	const navigate = useNavigate();


	//función click
	const onSubmit = async e => {
		e.preventDefault()

		await axios.get(baseUrlUsers, { params: { usuario: form.usuario, clave: md5(form.clave), unico: 'true' } })
			.then(response => {
				let respuesta = response.data
				if (respuesta && respuesta.activo === 'true' && parseInt(respuesta.roll) <= VISITANTE) {
					cambiarInformacionUser(respuesta)
					navigate(RUTAS.ADMIN_RESERVA);

				} else { setErrorForm('Usuario o Contraseña Incorrecta') }
			})
			.catch(error => {
				console.log(error)
				setErrorForm('No encuentra el servidor')
			})
	}

	//función escucha form
	const onChange = e => {
		setForm({ ...form, [e.target.name]: e.target.value })
	}

	useEffect(() => {
		if (user) {
			navigate(RUTAS.ADMIN_RESERVA);
		}
	}, [user])

	return (
		<div>
			<FormularioLogin
				onChange={onChange}
				form={form}
				onSubmit={onSubmit}
				errorForm={errorForm}
				sede={sede} />
		</div>
	)
}



export default Login