import React, { useState, useEffect, useContext } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'

import Header from '../componentes/Header';
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import { ApiPaths } from '../../utils.js';
import { Form } from 'react-bootstrap';
import UserContext from '../../context/UserContext';
import { SUPERADMIN } from '../../constantes/roles';
import moment from 'moment';

const SubirTickets = () => {
	//erroress
	const [error, setError] = useState({ activo: false })
	const [registro, setRegistro] = useState({ activo: true })
	const [isChecked1, setIsChecked1] = useState(false);
	const [isChecked2, setIsChecked2] = useState(false);

	const { user } = useContext(UserContext);

	const navigate = useNavigate()
	const peticionPost = async (e) => {
		e.preventDefault();

		// Validar si al menos uno de los checkboxes está seleccionado
		if (!isChecked1 && !isChecked2) {
			setError({ check: true });
			return;
		} else {
			setError({ check: false });
		}

		try {

			const fecha = moment(new Date()).format("YYYY-MM-DD-HH-mm-ss");
			const { nombre, detalle, pdf, codigo } = registro
			let formato = pdf ? pdf.type.replace('application/', '.') : null;
			let RutaServ = 'pdf/'
			let nombrePDF = pdf ? RutaServ + fecha + formato : ''

			const f = new FormData()
			f.append('nombre', nombre)
			f.append('detalle', detalle)
			f.append('id_operador', user.id_operador)
			f.append('id_usuario', user.id)
			f.append('codigoReserva', codigo)
			f.append('dirigido', isChecked1 ? 'Parques Nacionales' : isChecked2 ? 'Unión Temporal' : '')
			f.append('codigoTicket', moment().format("MMDDYYYYhhmmssSSS"))
			f.append('pdf', pdf)
			f.append('nombre_pdf', nombrePDF)
			f.append('METHOD', 'POST')
			await axios.post(ApiPaths.tickets, f)
			navigate(RUTAS.ADMIN_TICKETS)

		} catch (error) {
			console.log(error)
		}
	}

	const recibePDF = e => {
		let picture = e.target.files[0]
		setRegistro({ ...registro, pdf: picture })
	}


	const handleCheckboxChange = (checkboxNumber) => {
		if (checkboxNumber === 1) {
			setIsChecked1(true);
			setIsChecked2(false);
			setError({ check: false });
		} else {
			setIsChecked1(false);
			setIsChecked2(true);
			setError({ check: false });
		}
	};
	return (

		<div>
			<Header titulo='Subir Ticket' funcion='regresar' onClick={() => navigate(RUTAS.ADMIN_TICKETS)} Pagina={'Subir Ticket'} Agregar={true} />
			<div className="row">
				<div className="col-md-12">
					<Form onSubmit={peticionPost}>
						<div className="row">
							<div className="col-md-12">
								<div><hr />
									<MDBInput name="nombre"
										required
										className={error.nombre ? "form-control is-invalid" : null} outline
										label='Asunto'
										value={registro.nombre}
										onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
									/>

									<MDBInput name="Detalle"
										required
										className={error.nombre ? "form-control is-invalid" : null} outline
										label='Detalle'
										type='textarea'
										rows="5"
										value={registro.detalle}
										onChange={e => setRegistro({ ...registro, detalle: e.target.value })}
									/>

									<MDBInput name="Codigo"
										required
										className={error.codigo ? "form-control is-invalid" : null} outline
										label='Codigo de reserva'
										value={registro.codigo}
										onChange={e => setRegistro({ ...registro, codigo: e.target.value })}
									/>

									<div className="mb-3">
										<p>Dirigido a:</p>
										<div class="custom-control custom-checkbox custom-control-inline ">
											<input checked={isChecked1}
												onChange={() => handleCheckboxChange(1)}
												type="checkbox" className={`custom-control-input ${error.check && 'is-invalid'}`} id="defaultInline2" />
											<label class="custom-control-label" for="defaultInline2">Parques Nacionales</label>
										</div>

										<div class="custom-control custom-checkbox custom-control-inline">
											<input type="checkbox" className={`custom-control-input ${error.check && 'is-invalid'}`} id="defaultInline3" label="Opción 2"
												checked={isChecked2} onChange={() => handleCheckboxChange(2)} />
											<label class="custom-control-label" for="defaultInline3">Unión Temporal</label>
										</div>
									</div>
									<div className="input-group">
										<div className="input-group-prepend">
										</div>
										<div className="custom-file">
											<input
												type="file"
												className="custom-file-input"
												id="inputGroupFile01"
												aria-describedby="inputGroupFileAddon01"
												name="pdf"
												accept="application/pdf"
												onChange={recibePDF}
											/>
											<label className="custom-file-label">
												{registro.pdf ? registro.pdf?.name : 'Seleccionar pdf'}
											</label>
										</div>
									</div>


									<br />
									<div className="col text-center">
										<button type='submit'
											className="btn boton_estandar"
										>{`Subir Ticket`}
										</button>
									</div>
								</div>
							</div>
						</div>
					</Form>
				</div>
			</div >
		</div >
	)
}

export default SubirTickets