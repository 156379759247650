import React, { useState, useEffect } from 'react'
import VerTarifas from './VerTarifas'
import SubirEditTarifas from './SubirEditTarifas'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import { Titulos, peticionDelete } from '../hooks/Funciones'

import { ApiPaths } from '../../utils';

const TarifasAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Tarifas")
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [tarifas, setTarifas] = useTraeDatos(ApiPaths.tarifas)
	const [tiposIngresos, setTiposIngresos] = useTraeDatos(ApiPaths.ingresos)
	const TitulosPagina = ['Ver tarifas', 'Crear Tarifa', 'Editar Tarifa', 'Eliminar Tarifa', 'Tarifas']

	const seleccionaRegistro = (dato, funcion) => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen })
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () => { peticionDelete(registroSelect, ApiPaths.tarifas, tarifas, setTarifas, setFuncion) }
	useEffect(() => { Titulos(funcion, setTituloPag, TitulosPagina) }, [funcion])

	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} Agregar={true} />
			{funcion === 'crear' ?
				<SubirEditTarifas
					baseUrl={ApiPaths.tarifas}
					setFuncion={setFuncion}
					data={tarifas}
					setData={setTarifas}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					urlIngresos={ApiPaths.ingresos}
					funcion={funcion} />

				: null}
			{funcion === 'editar' ?
				<SubirEditTarifas
					baseUrl={ApiPaths.tarifas}
					setFuncion={setFuncion}
					data={tarifas}
					setData={setTarifas}
					TituloBtn={tituloPag}
					setTituloPag={setTituloPag}
					urlIngresos={ApiPaths.ingresos}
					registroSelect={registroSelect}
					funcion={funcion} />
				: null}
			{funcion === 'ver' ?
				<VerTarifas
					data={tarifas}
					seleccionaRegistro={seleccionaRegistro}
					tiposIngresos={tiposIngresos}
					baseUrl={ApiPaths.tarifas}
				/>
				: null}
			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null}
		</div>
	)
}

export default TarifasAdmin