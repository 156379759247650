import React, { useContext, useEffect, useState } from "react";
import useTraeDatos from "../hooks/useTraeDatos";
import { ApiPaths } from "../../utils";
import UserContext from "../../context/UserContext";
import { SUPERADMIN, VISITANTE } from "../../constantes/roles";

/**
 * Componente para seleccionar el tipo de ingreso y sendero.
 * @param {Object} props - Propiedades del componente.
 * @param {Function} props.updateIngresoSelected - Función para actualizar el ingreso seleccionado.
 * @param {Object} props.errors - Errores de validación.
 * @param {Object} props.value - Valor seleccionado.
 * @param {Array} props.senderosOptions - Opciones de senderos.
 * @param {Function} props.setSenderosOptions - Función para actualizar las opciones de senderos.
 */
const SelectTipoIngreso = ({ updateIngresoSelected, errors, value, senderosOptions, setSenderosOptions }) => {
  const { user } = useContext(UserContext);
  const [tiposIngresos] = useTraeDatos(ApiPaths.ingresos, { id: !user ? 2 : parseInt(user.roll) === VISITANTE ? 2 : null });

  /**
   * Valida si lo seleccionado tiene elementos hijos y renderiza; de no ser así, entonces envía el elemento seleccionado al componente padre.
   * @param {Event} e - Evento disparado por el select de tipo de ingreso o de sendero según sea el caso.
   */
  const SeleccionaTipoIngreso = (e) => {
    if (e.target.value == '') {
      setSenderosOptions([]);
      return updateIngresoSelected(null);
    }
    let hasSenderos = tiposIngresos.some(({ padre }) => padre === e.target.value);

    if (hasSenderos) {
      setSenderosOptions(tiposIngresos.filter(({ padre }) => padre == e.target.value));
      updateIngresoSelected(null);
    } else {
      let tipoSelected = tiposIngresos.find(({ id }) => id == e.target.value);
      if (tipoSelected.padre === "") setSenderosOptions([]);
      updateIngresoSelected(tipoSelected);
    }
  };

  useEffect(() => {
    if (value && value.padre) {
      const senderos = tiposIngresos.filter(({ padre }) => padre == value.padre);
      if (senderos) {
        setSenderosOptions(senderos);
      }
    }
  }, [tiposIngresos]);

  return (
    <>
      <div className={`${errors.tipoIngreso && 'is-invalid'} ContSelectIngreso`} style={{ marginBottom: 15 }}>
        <select
          className={"SelectIngreso"}
          onChange={SeleccionaTipoIngreso}
          value={value ? (value.padre ? value.padre : value.id) : null}
        >
          <option value={""}>Selecciona Tipo de Ingreso</option>
          {tiposIngresos.map((item, idx) => {
            if (item.tipo === 'Ingreso') {
              return <option key={idx} value={item.id}>{item.nombre}</option>;
            }
          })}
        </select>
      </div>
      {senderosOptions.length > 0 ?
        <div className='ContSelectIngreso' style={{ marginBottom: 15 }}>
          <select
            className={"SelectIngreso"}
            onChange={SeleccionaTipoIngreso}
            value={value ? (value.id ? value.id : value.id) : null}
          >
            <option value={""}>Selecciona Sendero</option>
            {senderosOptions.map((item, idx) => {
              if (item.id === '8' && parseInt(user.roll) !== SUPERADMIN) {
                return null;
              }
              return <option key={idx} value={item.id}>{item.nombre}</option>;
            })}
          </select>
        </div>
        : null}
    </>
  );
};

export default SelectTipoIngreso;