import { createContext, useCallback, useMemo, useState } from 'react';

// Creación del contexto de Reserva
const ReservaContext = createContext();

// Proveedor del contexto de Reserva
export const ReservaProvider = ({ children }) => {

    // Estado para el paso actual del proceso de reserva
    const [step, setStep] = useState(1);

    // Estado para el total de servicios seleccionados
    const [serviciosTotal, setServiciosTotal] = useState({})

    // Función para cambiar el total de servicios seleccionados
    const cambiarServiciosTotal = useCallback((data) => {
        setServiciosTotal(data)
    }, []);

    // Función para avanzar al siguiente paso del proceso de reserva
    const aumentarCambioVista = () => setStep(step + 1)

    // Función para retroceder al paso anterior del proceso de reserva
    const retrocederCambioVista = () => setStep(step - 1)

    // Función para eliminar un servicio del total de servicios seleccionados
    const eliminarServicio = (id) => {
        const servicios = { ...serviciosTotal }
        delete servicios[id]
        setServiciosTotal(servicios)
    }

    // Valor del contexto que se pasará a los componentes hijos
    const contextValue = useMemo(
        () => ({
            step,
            aumentarCambioVista,
            serviciosTotal,
            retrocederCambioVista,
            cambiarServiciosTotal,
            eliminarServicio
        }), [step, aumentarCambioVista, serviciosTotal, retrocederCambioVista, cambiarServiciosTotal, eliminarServicio],
    );

    // Proveedor del contexto de Reserva
    return (
        <ReservaContext.Provider value={contextValue}>
            {children}
        </ReservaContext.Provider>
    );

}
export default ReservaContext;