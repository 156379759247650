export const ADMIN = '/Admin'
export const ADMIN_RESERVA = '/Admin/reservas'
export const ADMIN_RESERVA_VER = '/Admin/reservas/:id'
export const ADMIN_ESCRITORIO = '/Admin/escritorio'
export const ADMIN_OPERADORES = '/Admin/operadores'
export const ADMIN_OPERADORES_CREAR = '/Admin/operadores/crear'
export const ADMIN_OPERADORES_EDITAR = '/Admin/operadores/editar'
export const ADMIN_TIPOS_INGRESO = '/Admin/tipos-ingresos'
export const ADMIN_SERVICIOS = '/Admin/servicios'
export const ADMIN_GUIAS = '/Admin/guias'
export const ADMIN_TRANSACCIONES = '/Admin/transacciones'
export const ADMIN_TARIFAS = '/Admin/tarifas'
export const ADMIN_VISITANTES = '/Admin/visitantes'
export const ADMIN_CONFIGURACION = '/Admin/configuracion'
export const ADMIN_INFORMES = '/Admin/informes'
export const ADMIN_USUARIOS = '/Admin/usuarios'
export const ADMIN_USUARIOS_CREAR = '/Admin/usuarios/crear'
export const ADMIN_USUARIOS_EDITAR = '/Admin/usuarios/editar/:id'
export const OPERADORES = '/operadores'
export const GUIAS = '/guias'
export const ADMIN_METODO_PAGO = '/Admin/metodosPago'
export const ADMIN_METODO_PAGO_CREAR = '/Admin/metodosPago/crear'
export const ADMIN_METODO_PAGO_EDITAR = '/Admin/metodosPago/editar/:id'
export const ADMIN_TICKETS = '/Admin/tickets'
export const ADMIN_TICKETS_CREAR = '/Admin/tickets/crear'
export const ADMIN_TICKETS_EDITAR = '/Admin/tickets/editar/:id'
export const ADMIN_TICKETS_RESPONDER = '/Admin/tickets/responder/:id'
export const ADMIN_BACKUPS = '/Admin/backups'
export const RESERVAS = '/reservas'
export const RECUPERAR_CONTRASENA = '/recuperar-contrasena'
export const ADMIN_RESERVAS_DIA = '/Admin/reservas-dia'
export const ESTADO_TRANSACCION = '/Admin/transaccion/:estado'
export const ESTADO_TRANSACCION_APROBADO = '/Admin/transaccion/aprobado'
export const ESTADO_TRANSACCION_RECHAZADO = '/Admin/transaccion/rechazado'
export const ESTADO_TRANSACCION_PENDIENTE = '/Admin/transaccion/pendiente'
export const TUTORIALES_ADMIN = 'https://drive.google.com/drive/folders/16SiiaXxUEEJDTzohhArJIHiCuoG4MOBS?usp=sharing'
export const TUTORIALES_OPERADOR = 'https://drive.google.com/drive/folders/1uUR-28wKOvIyYzrxZkp-EOKttDtC7g1n?usp=sharing'
export const HISTORIALRESERVAS = '/historialreservas'
export const HISTORIALRESERVASDETALLE = '/historialreservas/:id'
