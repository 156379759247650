import React from 'react'
import { MDBTable, MDBTableBody} from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
//import TraeNombre from '../hooks/useTraeNombre'
import {TraeNombre} from '../hooks/Funciones'

const VerTarifas = ({data, seleccionaRegistro, baseUrl, tiposIngresos}) => {

	return(
				<MDBTable hover>
				  <HeaderTabla array={['Nombre','Tarifa','Nacionalidad','Ingreso','Edades','']} />
			      <MDBTableBody>

			      	{data.map((item, index) => {
			      		return(	
					        <tr key={index} className="tabla_productos">
					          <td>{item.nombre}</td>
					          <td>{item.tarifa}</td>
							  <td>{item.nacionalidad}</td>
							  <td>{item.tipoingreso}</td>
							  <td>{item.edadinicial} - {item.edadfinal}</td>
                              <td className="tabla_funciones">
							  	<BotonesFormulario SeleccionarCategoria={seleccionaRegistro} item={item} Roll={'Admin'}/>
					          </td>
					        </tr>
				        )
			        })}

			      </MDBTableBody>
			    </MDBTable>
	)
}

export default VerTarifas