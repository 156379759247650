import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { ApiPaths } from '../../utils.js';
import Header from '../componentes/Header';
import { useNavigate } from 'react-router-dom';
import * as RUTAS from '../../constantes/rutas';
import Eliminar from '../componentes/Eliminar';
import useTraeDatos from "../hooks/useTraeDatos";
import UserContext from '../../context/UserContext';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import Tabla from '../componentes/Tabla';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faEdit, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

const VerTickets = () => {
	const navigate = useNavigate()
	const [funcion, setFuncion] = useState('ver')
	const [registroSelect, setRegistroSelect] = useState()
	const [data, setData] = useState([])
	const { user, cambiarInformacionTickets } = useContext(UserContext);

	useEffect(() => {
		const peticionDatos = async () => {
			try {
				if (user && parseInt(user.roll) === SUPERADMIN) {
					const resp = await axios.get(ApiPaths.tickets)
					let ticketsSinLeer = 0;
					resp && resp.data.map(ticket => {
						if (ticket.visto == 'false') {
							ticketsSinLeer += 1
						}
					})
					cambiarInformacionTickets(ticketsSinLeer)
					return setData(resp.data)
				}

				if (user && parseInt(user.roll) === TAQUILLA || user && parseInt(user.roll) === OPERADOR) {
					const resp = await axios.get(ApiPaths.tickets, { params: { idOperador: user.id_operador } })
					let ticketsSinLeer = 0;
					resp && resp.data.map(ticket => {
						if (ticket.visto == 'false') {
							ticketsSinLeer += 1
						}
					})
					cambiarInformacionTickets(ticketsSinLeer)
					return setData(resp.data)
				}

				if (user && parseInt(user.roll) === VISITANTE) {
					const resp = await axios.get(ApiPaths.tickets, { params: { idUsuario: user.id } })
					let ticketsSinLeer = 0;
					resp && resp.data.map(ticket => {
						if (ticket.visto == 'false') {
							ticketsSinLeer += 1
						}
					})
					cambiarInformacionTickets(ticketsSinLeer)
					return setData(resp.data)
				}

			} catch (error) {
				console.log(error)
			}
		}

		peticionDatos()
	}, [])

	const Seleccionar = async (objeto, funcion) => {
		try {
			if (funcion === "editar") {
				navigate(`${RUTAS.ADMIN_TICKETS}/editar/${objeto.id}`, { state: objeto })
			}

			if (funcion === "responder") {
				navigate(`${RUTAS.ADMIN_TICKETS}/responder/${objeto.id}`, { state: objeto })
			}
			if (funcion === "eliminar") {
				setFuncion(funcion)
				setRegistroSelect(objeto)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const Estados = (dato) => {
		if (dato === 'PENDIENTE') {
			return 'P'
		} else if (dato === 'APROBADO') {
			return 'A'
		} else if (dato === 'RECHAZADO') {
			return 'R'
		} else if (dato === 'CANCELADO') {
			return 'C'
		} else if (dato === 'COMPLETADO') {
			return 'C'
		}
	}
	const columns = [
		{
			title: "Estado", field: "estado", render: (item) => <> <div className='tickets-estados'>
				<span className={`estados ${item.estado}`}>{Estados(item.estado)}</span>
				<div className={item.visto === 'false' ? 'tickets-estados-button' : null}>
				</div>
			</div>
			</>,
			width: "10%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Id", field: "id", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Fecha", field: "fecha", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}
		},
		{
			title: "Codigo", field: "codigoTicket", width: "5%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			},
		},
		{
			title: "Asunto", field: "nombre",
			width: "10%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}, render: (item) => <div className='tickets-elipsis'>{item.nombre}</div>
		},
		{
			title: "Detalle", field: "detalle",
			width: "45%", cellStyle: {
				padding: 4
			}, headerStyle: {
				padding: 0
			}, render: (item) => <div className='tickets-elipsis'>{item.detalle}</div>
		},
		{
			title: "Operador", field: "operadorNombre"
		},
		{
			title: "Usuario", field: "usuarioNombre"
		},
		{
			title: "Dirigido", field: "dirigido"
		}
	];
	const actions = [
		(item) => ({
			icon: () => <div className="btn boton_estandar_blanco"> <FontAwesomeIcon icon={faEye} size="lg" /></div>,
			tooltip: "Ver",
			onClick: (event, rowData) => Seleccionar(item, 'editar')
		})]

	return (
		<div>
			<Header titulo='Ver Tickets' funcion={funcion} setFuncion={() => navigate(RUTAS.ADMIN_TICKETS_CREAR)} Pagina='Ver Tickets' Agregar={parseInt(user.roll) != SUPERADMIN && true} />

			{funcion === 'ver' ?
				<div className="row">
					<div className="col-md-12">
						<Tabla columns={columns} data={data} actions={actions} title='Listado de Tickets' />
					</div>
				</div>
				: null
			}



		</div>
	)
}

export default VerTickets