import React, { useState, useEffect, useContext } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import { Form } from 'react-bootstrap'
import { TraeNombre, traeIdNombre, errores } from '../hooks/Funciones'
import UserContext from '../../context/UserContext';

const EditarTipoIngreso = ({ baseUrl, setFuncion, data, setData, registroSelect, setRegistroSelect }) => {

	const { cambiarInformacionTiposIngreso } = useContext(UserContext)
	//errores
	const [error, setError] = useState({ activo: false })
	const [registro, setRegistro] = useState({ ...registroSelect, padre: TraeNombre(data, registroSelect.padre), alertas: JSON.parse(registroSelect.alertas), multipleDias: (registroSelect.multipleDias === 'true') })
	const [claveNueva, setClaveNueva] = useState('')

	const [agotado, setAgotado] = useState({ nombre: 'Sin Cupo', rangofin: 0 })
	const [pocasExis, setPocasExis] = useState({ nombre: 'Poco Cupo', rangoini: 0, rangofin: 0 })
	const [conExist, setconExist] = useState({ nombre: 'Con Disponibilidad', rangoini: 0 })

	useEffect(() => {
		setAgotado(registro.alertas[0])
		setPocasExis(registro.alertas[1])
		setconExist(registro.alertas[2])
	}, [registro])
	//const [operadores] = useTraeDatos(UrlOperadores)

	const peticionPut = async () => {
		let { id, nombre, tipo, padre, capacidadlimite, capacidadactual, descripcion, multipleDias, restriccionDias } = registro
		let AlertaFull = [agotado, pocasExis, conExist]
		if (nombre !== undefined && tipo !== undefined && padre !== undefined && capacidadlimite !== undefined && capacidadactual !== undefined && restriccionDias !== undefined) {
			let f = new FormData()
			f.append('nombre', nombre)
			f.append('tipo', tipo)
			f.append('padre', traeIdNombre(data, padre))
			f.append('capacidadlimite', capacidadlimite)
			f.append('descripcion', descripcion)
			f.append('capacidadactual', capacidadactual)
			f.append('alertas', JSON.stringify(AlertaFull))
			f.append('multipleDias', multipleDias)
			f.append('restriccionDias', restriccionDias)
			f.append("METHOD", "PUT")
			await axios.post(baseUrl, f, { params: { id: id } })
				.then(response => {
					let dataNueva = data
					dataNueva.map(item => {
						if (item.id === id) {
							item.nombre = nombre
							item.tipo = tipo
							item.padre = padre
							item.capacidadlimite = capacidadlimite
							item.descripcion = descripcion
							item.capacidadactual = capacidadactual
							item.alertas = JSON.stringify(AlertaFull)
							item.multipleDias = multipleDias
							item.padre = traeIdNombre(data, padre)
							item.restriccionDias = restriccionDias
						}
					})
					setData(dataNueva)
					setFuncion('ver')

					cambiarInformacionTiposIngreso()
				}).catch(error => {
					console.log(error);
				})
		} else errores('CampoVacio', setError)
	}

	return (
		<div>
			<div className="row">
				<div className="col-md-12">
					{error.activo ?
						<span className="error"> Error: {error.mensaje}</span> : null
					}
					<div><hr />
						<MDBInput
							label="Nombre del Ingreso" name="nombre" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline
							value={registro.nombre}
							onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
						/>

						<div className='row'>
							<div className='col-md-6'>
								<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
									value={registro.tipo}
									onChange={e => setRegistro({ ...registro, tipo: e.target.value })}>
									<option value={""}>Selecciona una opción</option>
									<option value={"Ingreso"}>Ingreso</option>
									<option value={"Sendero"}>Sendero</option>
								</select>
							</div>

							<br></br>

							<div className='col-md-6'>
								<select className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="sede"
									value={registro.padre}
									onChange={e => setRegistro({ ...registro, padre: e.target.value })}>
									<option value={""}>Selecciona un Ingreso</option>
									{data.map((item, index) => {
										if (item.tipo === "Ingreso") {
											return <option key={index} value={item.nombre}>{item.nombre}</option>
										}
									})}
									<option value={"Ninguno"}>Ninguno</option>
								</select>
							</div>
						</div>
						<div className='row'>
							<div className='col-md-4'>
								<MDBInput name="usuario" required className={error.campoUsuario ? "form-control is-invalid" : null} outline
									label={'Capacidad Máxima'}
									value={registro.capacidadlimite}
									onChange={e => setRegistro({ ...registro, capacidadlimite: e.target.value })}
								/>

							</div>
							<div className='col-md-4 d-flex align-items-center' >
								<MDBInput
									name="restriccionDias"
									required
									outline
									label={'Restricción de dias calendario'}
									value={registro.restriccionDias}
									type='number'
									onChange={e => setRegistro({ ...registro, restriccionDias: e.target.value })}
								/>
							</div>
							<div className='col-md-4 d-flex align-items-center' >
								<Form.Check
									type='checkbox'
									id={`default-checkbox`}
									label={`Multiple dias`}
									checked={registro.multipleDias}
									onChange={e => setRegistro({ ...registro, multipleDias: e.target.checked })}
								/>
							</div>
						</div>


						<MDBInput label="Descripción" name="descripcion" required className={error.campoClave ? "form-control is-invalid" : null} outline
							value={registro.descripcion}
							onChange={e => setRegistro({ ...registro, descripcion: e.target.value })}
						/>

						<h5>Alertas:</h5>
						<div className="row">

							<div className="col-md-4"> <br></br><p className="estados Rojo">Sin Cupo</p></div>
							<div className="col-md-8"><MDBInput label="Rango Menor a " outline
								value={agotado.rangofin}
								onChange={e => setAgotado({ ...agotado, rangofin: e.target.value })} />
							</div>


							<div className="col-md-4"> <br></br><p className="estados Amarillo">Con pocos Cupos</p></div>
							<div className="col-md-4"><MDBInput label="Rango Inicial " outline
								value={pocasExis.rangoini}
								onChange={e => setPocasExis({ ...pocasExis, rangoini: e.target.value })} />
							</div>
							<div className="col-md-4"><MDBInput label="Rango Final" outline
								value={pocasExis.rangofin}
								onChange={e => setPocasExis({ ...pocasExis, rangofin: e.target.value })} />
							</div>


							<div className="col-md-4"> <br></br><p className="estados Verde">Con Disponibilidad</p></div>
							<div className="col-md-8"><MDBInput label="Rango Mayor a" outline
								value={conExist.rangoini}
								onChange={e => setconExist({ ...conExist, rangoini: e.target.value })} />
							</div>


						</div>

						<br />
						<div className="col text-center">
							<button
								onClick={peticionPut}
								className="btn boton_estandar"
							>{`Editar Tipo de Ingreso`}
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EditarTipoIngreso