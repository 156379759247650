import React, { useState, useEffect, useContext } from 'react'
import { MDBInput } from 'mdbreact';
import axios from 'axios'
import Header from '../componentes/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiPaths } from '../../utils.js';
import * as RUTAS from '../../constantes/rutas';
import { Form } from 'react-bootstrap';
import { OPERADOR, SUPERADMIN, TAQUILLA, VISITANTE } from '../../constantes/roles';
import UserContext from '../../context/UserContext';
const EditarUsuario = () => {

	const { state: usuarioSelect } = useLocation()
	const { user } = useContext(UserContext);
	const navigate = useNavigate()
	//errores
	const [error, setError] = useState({ activo: false })
	//almacena toda la data de  
	const [registro, setRegistro] = useState(usuarioSelect)
	const [claveNueva, setClaveNueva] = useState('')
	const [operadores, setOperadores] = useState([])

	useEffect(() => {
		const operadores = async () => {
			try {
				const operadores = await axios(ApiPaths.operadores, { params: { activo: true } });
				setOperadores(operadores.data);
			} catch (error) {
				setOperadores([])
			}
		}
		operadores()
	}, [])


	if (!usuarioSelect) return <p>Error al ingresar</p>
	//Busca Id de Operador para añadirlo a tabla de usuarios como relación

	const peticionPut = async (e) => {
		e.preventDefault();
		try {
			const { activo, usuario, clave, roll, nombre, operadorId, telefono, correo, documento } = registro

			const f = new FormData()
			f.append('activo', activo.toString())
			f.append('usuario', usuario)
			f.append('clave', clave || '')
			f.append('roll', parseInt(user.roll) === OPERADOR ? OPERADOR : roll)
			f.append('nombre', nombre)
			f.append('operadorId', parseInt(user.roll) === OPERADOR ? user.id_operador : operadorId)
			f.append('telefono', telefono)
			f.append('correo', correo)
			f.append('documento', documento)
			f.append("METHOD", "PUT")
			await axios.post(ApiPaths.usuarios, f, { params: { id: registro.id } })
			navigate(RUTAS.ADMIN_USUARIOS)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<div>
			<Header titulo='Editar Usuario' funcion='regresar' onClick={() => navigate(RUTAS.ADMIN_USUARIOS)} Pagina={'Usuarios'} Agregar={true} />
			<div className="row">
				<div className="col-md-12">
					<Form onSubmit={peticionPut}>
						{error.activo ?
							<span className="error"> Error: {error.mensaje}</span> : null
						}
						<div><hr />
							<div className='custom-control custom-switch '>
								<input type='checkbox' name="activo" className='custom-control-input form-control-lg mb-4' id='customSwitches'
									checked={String(registro.activo) == "true" ? true : false}
									onChange={e => setRegistro({ ...registro, activo: e.target.checked })}
								/>
								<label className='custom-control-label' htmlFor='customSwitches'>Activar/Desactivar</label>
							</div>


							<MDBInput name="usuario"
								required
								className={error.campoUsuario ? "form-control is-invalid" : null} outline
								label={'Usuario'}
								value={registro.usuario}

								onChange={e => setRegistro({ ...registro, usuario: e.target.value })}
							/>
							<MDBInput
								label="Correo" name="correo" required outline
								value={registro.correo}
								type='email'
								onChange={e => setRegistro({ ...registro, correo: e.target.value })}
							/>

							<MDBInput
								label="Clave"
								type="password"
								name="clave"
								className={error.campoClave ? "form-control is-invalid" : null} outline
								onChange={e => setRegistro({ ...registro, clave: e.target.value })}
							/>

							<MDBInput label="Teléfono" name="telefono" required className={error.campoClave ? "form-control is-invalid" : null} outline
								value={registro.telefono}
								onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
							/>

							<MDBInput
								label="Nombre Completo del Usuario" name="nombre" required className={error.campoNombreUser ? "form-control is-invalid" : null} outline
								value={registro.nombre}
								onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
							/>
							<MDBInput
								label="Numero de Documento" name="documento" required outline
								value={registro.documento}
								onChange={e => setRegistro({ ...registro, documento: e.target.value })}
							/>
							{(parseInt(user.roll) === SUPERADMIN) &&
								<div className='mb-4'>
									<select
										className={error.campoSede ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"}
										name="sede"
										required
										value={registro.operadorId}
										onChange={e => setRegistro({ ...registro, operadorId: e.target.value })}>
										<option value={""}>Selecciona un operador</option>
										{operadores.map((item, index) => {
											return <option key={index} value={item.id}>{item.nombre}</option>
										})}

									</select>
								</div>
							}

							{(parseInt(user.roll) === SUPERADMIN) && <div>

								<select
									className={error.campoRoll ? "form-control is-invalid browser-default custom-select selectAtri" : "browser-default custom-select selectAtri"} name="roll" required
									value={registro.roll}
									onChange={e => setRegistro({ ...registro, roll: e.target.value })}>
									<option value="">Selecciona Rol</option>
									{parseInt(user.roll) === SUPERADMIN && <option value={SUPERADMIN}>SUPERADMIN</option>}
									<option value={OPERADOR}>OPERADOR</option>
									<option value={TAQUILLA}>TAQUILLA</option>
									<option value={VISITANTE}>VISITANTE</option>
								</select>

							</div>}

							<br />
							<div className="col text-center">
								<button
									type='submit'
									className="btn boton_estandar"
								>{`Editar Usuario`}
								</button>
							</div>
						</div>
					</Form>
				</div>
			</div>
		</div>
	)
}

export default EditarUsuario