import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faVideo, faTachometerAlt, faMountainCity, faArrowRight, faCreditCardAlt, faBinoculars, faChartBar, faCogs, faSignOut, faUserFriends, faCalendarDays, faTicket, faTaxi, faCoins, faUsers, faMoneyBill, faMessage, faCloudArrowUp, faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons' // <-- import styles to be used

const BotonAdmin = ({ nombre, sub, active, roll, verPara }) => {

	// Función para encontrar el icono correspondiente según el nombre
	const EncuentraIcono = icon => {

		switch (icon) {
			case 'Escritorio': return faTachometerAlt
			case 'Operadores': return faMountainCity
			case 'Guias': return faBinoculars
			case 'Transacciones': return faCreditCardAlt
			case 'Reservas': return faCalendarDays
			case 'Servicios': return faTaxi
			case 'Informes': return faChartBar
			case 'Usuarios': return faUserFriends
			case 'Tipos de Ingresos': return faTicket
			case 'Configuración': return faCogs
			case 'Cerrar Sesión': return faSignOut
			case 'Tarifas': return faCoins
			case 'Visitantes': return faUsers
			case 'Metodos de Pago': return faMoneyBill
			case 'Tickets': return faMessage
			case 'Respaldo': return faCloudArrowUp
			case 'Reservas del Día': return faInfoCircle
			case 'Tutoriales Admin': return faVideo
			case 'Tutoriales': return faVideo
			case 'Historial Reservas': return faCalendarDays
			default: return faArrowRight
		}
	}


	return (

		<div
			// className={sub ? "sub-item" : "item"}
			className={active ? "item active" : "item"
			}>
			{/* Renderiza el icono y el nombre del botón */}
			<FontAwesomeIcon icon={EncuentraIcono(nombre)} size="1x" />
			{' ' + nombre}
		</div >
	)
}



export default BotonAdmin