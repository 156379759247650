import React from 'react'
import { MDBTable, MDBTableBody } from 'mdbreact';
import BotonesFormulario from '../componentes/BotonesFormulario'
import HeaderTabla from '../componentes/HeaderTabla'
import Tabla from '../componentes/Tabla';

const VerVisitantes = ({ data, seleccionaRegistro, baseUrl, tiposIngresos }) => {
	const columns = [
		{
			title: 'Fecha Visita', field: "FechaVisita"
		},
		{
			title: "Nombre", field: "Nombre"
		},
		{
			title: "Tipo Doc", field: "TipoDocumento"
		},
		{
			title: "Documento", field: "Documento"
		},
		{
			title: "Aseguradora", field: "Aseguradora"
		},
		{
			title: "Cod Seguro", field: "CodigoSeguro"
		},
		{
			title: "Edad", field: "Edad"
		},
		{
			title: "Cod Reserva", field: "CodigoReserva"
		},
	];

	return (
		<Tabla columns={columns} data={data} actions={[]} title='Listado de Visitantes' />
	)
}

export default VerVisitantes