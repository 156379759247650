import { MDBInput } from 'mdbreact'
import React, { useContext, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import ReservaContext from '../../../context/ReservaContext'
import TablaResumen from '../TablaResumen'
import { useForm } from 'react-hook-form'
import { ApiPaths } from '../../../utils'
import axios from 'axios'
import md5 from 'md5'
import { toast } from 'react-toastify'
import { VISITANTE } from '../../../constantes/roles'

const CrearUsuario = () => {
    // Obtener funciones y estados del contexto de reserva
    const { aumentarCambioVista, serviciosTotal, retrocederCambioVista, step, cambiarServiciosTotal } = useContext(ReservaContext)
    const [registro, setRegistro] = useState({ ...serviciosTotal.registro })
    const { register, handleSubmit, watch, control, setValue, formState: { errors } } = useForm({});
    const [vista, setVista] = useState(serviciosTotal.vista)
    const [error, seterror] = useState({})
    const baseUrlUsers = ApiPaths.usuarios;

    // Función que se ejecuta al enviar el formulario
    const onSubmit = async (data) => {
        try {
            if (vista) {
                // Validar usuario existente
                const respuesta = await axios(baseUrlUsers, { params: { usuario: registro.usuario, clave: md5(registro.clave), unico: 'true' } })
                if (!respuesta.data) return seterror({ usuario: true, clave: true })
                seterror({})
                toast.success('Ingreso con exito')
                cambiarServiciosTotal({
                    ...serviciosTotal,
                    registro: { ...respuesta.data, clave: registro.clave },
                    vista
                })
                return aumentarCambioVista()
            }

            // Crear nuevo usuario
            const { usuario, clave, nombre, telefono, correo, cedula } = registro
            const f = new FormData()
            f.append('activo', 'true')
            f.append('usuario', usuario)
            f.append('clave', clave)
            f.append('roll', VISITANTE)
            f.append('nombre', nombre)
            f.append('operadorId', 7)
            f.append('telefono', telefono)
            f.append('correo', correo)
            f.append('documento', cedula)
            f.append('METHOD', 'POST')
            const respuestaUser = await axios.post(ApiPaths.usuarios, f)
            if (!respuestaUser.data?.id) {
                toast.error(respuestaUser.data)
                return seterror({ usuario: true, correo: true })
            }
            toast.success('Usuario creado con exito')
            cambiarServiciosTotal({
                ...serviciosTotal,
                registro: { ...respuestaUser.data, clave: registro.clave },
                vista
            })
            aumentarCambioVista()

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='crear-usuario-visitantes d-flex'>
                <div className='col-md-8'>
                    <div className={`CampoServicios row`}>
                        <h4 className='col-12 HeaderTablaServicios'>Crear usuario</h4>
                        {!vista ?
                            <>
                                <Row>
                                    <Form.Group as={Col} md="6">
                                        <MDBInput name="usuario"
                                            required
                                            outline
                                            label={'Usuario'}
                                            className={error.usuario && 'is-invalid'}
                                            value={registro.usuario}
                                            onChange={e => setRegistro({ ...registro, usuario: e.target.value })}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <MDBInput
                                            label="Correo" name="correo"
                                            required
                                            outline
                                            value={registro.correo}
                                            type='email'
                                            className={error.correo && 'is-invalid'}
                                            onChange={e => setRegistro({ ...registro, correo: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">

                                        <MDBInput
                                            label="Clave"
                                            type="password"
                                            name="clave"
                                            required
                                            outline
                                            value={registro.clave}
                                            onChange={e => setRegistro({ ...registro, clave: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <MDBInput label="Teléfono" name="telefono" required
                                            outline
                                            value={registro.telefono}
                                            onChange={e => setRegistro({ ...registro, telefono: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <MDBInput
                                            label="Nombre Completo del Usuario" name="nombre" required
                                            outline
                                            value={registro.nombre}
                                            onChange={e => setRegistro({ ...registro, nombre: e.target.value })}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6">
                                        <MDBInput
                                            label="Numero de Documento" name="cedula" required outline
                                            value={registro.cedula}
                                            onChange={e => setRegistro({ ...registro, cedula: e.target.value })}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className='text-center'>
                                    <div className='link-ingreso-visitante' onClick={() => setVista(true)}>
                                        Si tienes una cuenta <span>ingresa aca</span>
                                    </div>
                                </Row>
                            </>

                            :
                            <>
                                <Row>
                                    <Form.Group as={Col} md="6">
                                        <MDBInput name="usuario"
                                            required
                                            outline
                                            label={'Usuario'}
                                            value={registro.usuario}
                                            className={error.usuario && 'is-invalid'}
                                            onChange={e => setRegistro({ ...registro, usuario: e.target.value })}
                                        />

                                    </Form.Group>
                                    <Form.Group as={Col} md="6">

                                        <MDBInput
                                            label="Clave"
                                            type="password"
                                            name="clave"
                                            required
                                            outline
                                            value={registro.clave}
                                            className={error.clave && 'is-invalid'}
                                            onChange={e => setRegistro({ ...registro, clave: e.target.value })}
                                        />
                                    </Form.Group>

                                </Row>
                                {error.usuario && error.clave && <Row className='text-center'>
                                    <p>Error en la clave o usuario, por favor valide e intente de nuevo</p>
                                </Row>}
                                <Row className='text-center'>
                                    <div className='link-ingreso-visitante' onClick={() => setVista(false)}>
                                        Si no tienes una cuenta <span>ingresa aca</span>
                                    </div>
                                </Row>
                            </>

                        }


                    </div>

                </div>
                <div className='col-md-4'>
                    <TablaResumen
                        serviciosTotal={serviciosTotal}
                        botonAtras={retrocederCambioVista}
                        step={step}
                    />
                </div>
            </div>
        </Form>
    )
}

export default CrearUsuario