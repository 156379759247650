import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'

//Trae un nombre atraves de un id, que este almacenado en un array
// Trae nombre cuando en la tabla hay campos con Padre, o categoría en la misma tabla
const TraeNombre = (Data, id) => {
    let nombre = ''
    Data.map(item => {
        if (item.id === id) {
            nombre = item.nombre
        }
    })
    return nombre
}

//Trae el id de un nombre que está almacenado en un array
const traeIdNombre = (data, nombre) => {
    let resultado = ''
    data.map(item => {
        if (item.nombre === nombre) { resultado = item.id }
    })
    return resultado
}

//Busca en un array si un registro existe o no, se antes de los metodos post para
//no guardar el mismo registro
const existeRegistro = (data, nombre) => {
    let resultado = false
    data.map((item) => {
        if (item.nombre === nombre)
            resultado = true
    })
    return resultado
}

//Errores de formularios en el crud
const errores = (codigo, setError) => {
    switch (codigo) {
        case 'Existe':
            setError({ activo: true, mensaje: 'el registro ya existe', nombre: true })
            break
        case 'CampoVacio':
            setError({ activo: true, mensaje: 'Hay campos vacios' })
            break
        case 'ImagenIncorrecta':
            setError({ activo: true, mensaje: 'Formato de Imagen incorrecto o muy pesado' })
            break
        case 'Limpio':
            setError({ activo: false, mensaje: '' })
            break
        default:
            setError({ activo: false, mensaje: '' })
            break

    }
}

//Función que Organiza los titulos del crud
const Titulos = (funcion, setTituloPag, Array) => {
    switch (funcion) {
        case 'ver': return setTituloPag(Array[0])
        case 'crear': return setTituloPag(Array[1])
        case 'editar': return setTituloPag(Array[2])
        case 'eliminar': return setTituloPag(Array[3])
        default: return setTituloPag(Array[4])
    }
}

//Elimina el registro de una base detos y devuelve el arreglo con el registro eliminado
const peticionDelete = async (registroSelect, baseUrl, data, setData, setFuncion) => {
    console.log('id_elemento', registroSelect.id)
    let f = new FormData();
    f.append("METHOD", "DELETE");
    await axios.post(baseUrl, f, { params: { id: registroSelect.id, imagen: registroSelect.imagen } })
        .then(response => {
            setData(data.filter(opera => opera.id !== registroSelect.id))
            console.log('respuesta detele', response)
            setFuncion('ver')
        }).catch(error => { console.log(error) })
}

//Formatea la imagen para añadirla al registro que se va a subir o editar
const recibeImagen = (registro, setRegistro, funcion) => e => {
    if (funcion === 'editar') {
        let imagen = e.target.files[0]
        const fecha = moment(new Date()).format("YYYYMMDD-HHmmss");
        let formato = imagen.type.replace('image/', '.');
        let NombreImagen = imagen.name.replace(/[^A-Z0-9]+/ig, "_");
        let RutaServ = 'images/'

        setRegistro({
            ...registro,
            imagePrev: URL.createObjectURL(imagen),
            imagenNueva: imagen,
            nombreImaNueva: RutaServ + fecha + NombreImagen + formato
        })
    } else if (funcion === 'crear') {
        let picture = e.target.files[0]
        setRegistro({ ...registro, imagePrev: URL.createObjectURL(picture), imagen: picture })
    }
}

//Traduce el día del Mes 
const traduceMes = (mes) => {
    switch (mes) {
        case 'December': return 'Diciembre'; break;
        case 'January': return 'Enero'; break;
        case 'February': return 'Febrero'; break;
        case 'March': return 'Marzo'; break;
        case 'April': return 'Abril'; break;
        case 'May': return 'Mayo'; break;
        case 'June': return 'Junio'; break;
        case 'July': return 'Julio'; break;
        case 'August': return 'Agosto'; break;
        case 'September': return 'Septiembre'; break;
        case 'October': return 'Octubre'; break;
        case 'November': return 'Noviembre'; break;
        default: console.log(mes);
    }
}


export {
    TraeNombre,
    traeIdNombre,
    existeRegistro,
    errores,
    Titulos,
    peticionDelete,
    recibeImagen,
    traduceMes
}

