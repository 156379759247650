import React, { useState, useEffect } from 'react'
import VerTransacciones from './VerTransacciones'
import Eliminar from '../componentes/Eliminar'
import Header from '../componentes/Header';
import useTraeDatos from '../hooks/useTraeDatos';
import { Titulos, peticionDelete } from '../hooks/Funciones'
import { ApiPaths } from '../../utils';

const TransaccionesAdmin = () => {

	const [tituloPag, setTituloPag] = useState("Transacciones")
	const [registroSelect, setRegistroSelect] = useState({})
	const [funcion, setFuncion] = useState('ver')
	const [transacciones, setTransacciones] = useTraeDatos(ApiPaths.transacciones)
	const TitulosPagina = ['Ver Transacciones', 'Crear Transacciones', 'Editar Transacciones', 'Eliminar Transaccione', 'Transacciones']

	const seleccionaRegistro = (dato, funcion) => {
		setRegistroSelect({ ...dato, imagePrev: dato.imagen })
		setFuncion(funcion === 'eliminar' ? 'eliminar' : 'editar')
	}

	const peticionDeletee = () => { peticionDelete(registroSelect, ApiPaths.transacciones, transacciones, setTransacciones, setFuncion) }
	useEffect(() => { Titulos(funcion, setTituloPag, TitulosPagina) }, [funcion])


	return (
		<div>
			<Header titulo={tituloPag} funcion={funcion} setFuncion={setFuncion} Pagina={'Operadores'} />
			{funcion === 'ver' ?
				<VerTransacciones
					data={transacciones}
					seleccionaRegistro={seleccionaRegistro}
					baseUrl={ApiPaths.transacciones}
				/>
				: null}
			{funcion === 'eliminar' ?
				<Eliminar
					nombre={'Operador'}
					select={registroSelect}
					setFuncion={setFuncion}
					peticionDelete={peticionDeletee} />

				: null}
		</div>
	)
}

export default TransaccionesAdmin